import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import CourseContext from "components/contexts/CourseContext";
import SemestersContext from "components/contexts/SemestersContext";

import WithMyAcademics from "./WithMyAcademics";
import WithSemesters from "./WithSemesters";

export default function WithCurrentCourse({ children, courseId, semesterId }) {
  // If classId is not set by params, default to the courseID prop (allows for
  // more straightforward testing outside of the full app context)
  const { classId = courseId } = useParams();

  return (
    <WithMyAcademics>
      <WithSemesters semesterId={semesterId}>
        <SemestersContext.Consumer>
          {({ currentSemester, loadState }) => {
            const currentCourse =
              currentSemester &&
              currentSemester.classes.find(
                (course) => course.course_id === classId
              );

            return (
              <CourseContext.Provider
                value={{
                  currentSemester,
                  currentCourse,
                  loadState,
                }}
              >
                {children}
              </CourseContext.Provider>
            );
          }}
        </SemestersContext.Consumer>
      </WithSemesters>
    </WithMyAcademics>
  );
}

WithCurrentCourse.propTypes = {
  children: PropTypes.node,
  semesterId: PropTypes.string,
  courseId: PropTypes.string,
};
