import { createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import academics from "./AcademicsReducer";
import advising from "./AdvisingReducer";
import apiLinks from "./apiLinks";
import awardComparison from "./AwardComparisonReducer";
import awardComparisonSnapshot from "./AwardComparisonSnapshotReducer";
import billingItems from "./BillingItemsReducer";
import currentRoute from "./RouteReducer";
import finances from "./finances";
import financialAid from "./FinancialAidReducer";
import messageCatalog from "./messageCatalog";
import myHolds from "./HoldsReducer";
import publicLinks from "./publicLinksReducer";
import reactOnly from "./reactOnly";
import storedUsers from "./storedUsers";
import serviceAlerts from "./ServiceAlertsReducer";
import tuitionCohort from "./tuitionCohort";

import { userProfile } from "data/slices/userProfile";
import users from "./users";

import billingActivity from "../slices/billingActivity";

import byCourse from "data/slices/byCourse";
import byUser from "../slices/byUser";
import { config } from "../slices/config";
import { csrfToken } from "../slices/csrfToken";
import currentUID from "../slices/currentUID";
import { featureFlags } from "../slices/featureFlags";
import { myStatus } from "../slices/myStatus";
import { serverInfo } from "../slices/serverInfo";
import { showPNPCard } from "../slices/showPNPCard";

import {
  AgreementsReducer as myAgreements,
  BCalScheduleReducer as bCalSchedule,
  BCoursesActivitiesReducer as myBCoursesActivities,
  BCoursesTodosReducer as myBCoursesTodos,
  ChecklistItemsReducer as myChecklistItems,
  EftEnrollmentReducer as myEftEnrollment,
  EnrollmentsReducer as myEnrollments,
  FinancialResourcesLinksReducer as financialResourcesLinks,
  LawAwardsReducer as myLawAwards,
  MyAcademicsReducer as myAcademics,
  ProfileReducer as myProfile,
  RegistrationsReducer as myRegistrations,
  SirStatusReducer as sirStatus,
  StandingsReducer as myStandings,
  StatusAndHoldsReducer as myStatusAndHolds,
  TransferCreditReducer as myTransferCredit,
  MyUpNextReducer as myUpNext,
  WebMessagesReducer as myWebMessages,
} from "./DataReducers";

export const reset = createAction("app/reset");

const CombinedReducer = combineReducers({
  academics,
  advising,
  apiLinks,
  awardComparison,
  awardComparisonSnapshot,
  bCalSchedule,
  billingItems,
  billingActivity,
  byCourse,
  byUser,
  config,
  csrfToken,
  currentRoute,
  finances,
  financialAid,
  financialResourcesLinks,
  messageCatalog,
  myAcademics,
  myAgreements,
  myBCoursesActivities,
  myBCoursesTodos,
  myChecklistItems,
  myEftEnrollment,
  myEnrollments,
  myHolds,
  myLawAwards,
  myProfile,
  myRegistrations,
  myStandings,
  myStatus,
  myStatusAndHolds,
  myTransferCredit,
  myUpNext,
  myWebMessages,
  publicLinks,
  serverInfo,
  serviceAlerts,
  showPNPCard,
  sirStatus,
  storedUsers,
  userProfile,
  users,
  currentUID,
  reactOnly,
  tuitionCohort,
  featureFlags,
});

const AppReducer = (state = {}, action) => {
  if (action.type === "app/reset") {
    state = undefined;
  }

  return CombinedReducer(state, action);
};

export default AppReducer;
