import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { termFromId } from "components/../react/helpers/terms";

import Dropdown from "../../../../react/components/Dropdown";

const TermDropdown = ({ value, termIds, onChange }) => {
  const terms = termIds.map(termFromId);

  const options = [
    { label: "All Terms", value: "all" },
    ...terms.map((term) => ({
      value: term.id,
      label: `${term.semester} ${term.year}`,
    })),
  ];

  return (
    <TermDropdownContainer>
      <label>Showing</label>

      <Dropdown value={value} options={options} onChange={onChange} />
    </TermDropdownContainer>
  );
};
TermDropdown.displayName = "TermDropdown";
TermDropdown.propTypes = {
  value: PropTypes.string,
  termIds: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default TermDropdown;
export const TermDropdownContainer = styled.div`
  select {
    cursor: pointer;
  }

  label {
    font-size: 10px;
    text-transform: uppercase;
    line-height: 32px;
  }

  @media only screen and (min-width: 576px) {
    display: flex;

    select {
      margin-left: 10px;
    }
  }
`;
