import React from "react";
import { ErrorBoundary } from "components/ui/ErrorBoundary";
import { StudentSuccessCardUI } from "./StudentSuccessCard/StudentSuccessCardUI";

export function StudentSuccessCard() {
  return (
    <ErrorBoundary title="Student Success">
      <StudentSuccessCardUI />
    </ErrorBoundary>
  );
}

StudentSuccessCard.displayName = "StudentSuccessCard";
