import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CCTable } from "components/ui/Table";
import { PNPContext } from "./PNPContext";
import usePopover from "functions/usePopover";
import InfoIcon from "components/ui/InfoIcon";
import ButtonAsLink from "components/ui/ButtonAsLink";
import Popover from "components/ui/Popover";
import StyledMessage from "components/ui/StyledMessage";

export function InputTable() {
  const {
    baseTransferUnits,
    baseGpaUnits,
    baseNonGpaUnits,
    addTransferUnits,
    addGpaUnits,
    addNonGpaUnits,
    setAddTransferUnits,
    setAddGpaUnits,
    setAddNonGpaUnits,
    transferUnits,
    gpaUnits,
    nonGpaUnits,
    grandTotalUnits,
  } = useContext(PNPContext);

  const popover = usePopover();

  return (
    <PaddedCCTable>
      <thead>
        <tr>
          <th>Unit Type</th>
          <th>Current Units</th>
          <th>Units to Add</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ position: `relative` }}>
          <th>
            Transfer Credit{" "}
            <ButtonAsLink onClick={popover.open}>
              <InfoIcon />
            </ButtonAsLink>
            {popover.isOpen && (
              <Popover
                ref={popover.ref}
                title="Transfer Credit"
                close={popover.close}
              >
                <StyledMessage style={{ fontWeight: `normal` }}>
                  <p>
                    Transfer credit includes both course and exam credit. Only
                    exams completed as a high school student are eligible for
                    exam credit. Lower division transfer course credit may not
                    exceed 70 semester units. If you have reached this limit
                    your only options for additional transfer credit are any
                    course(s):
                  </p>
                  <ul>
                    <li>
                      Completed at another University of California (UC) campus
                      or UC Extension numbered 1-199.
                    </li>
                    <li>
                      Completed at an institution outside of the UC and CCC
                      system, and documented in the catalog as an upper division
                      level course.
                    </li>
                  </ul>
                </StyledMessage>
              </Popover>
            )}
          </th>
          <td>{baseTransferUnits.toFixed(2)}</td>
          <td>
            <InputUnits
              value={addTransferUnits}
              change={setAddTransferUnits}
              minimum={0}
            />
          </td>
          <td>{transferUnits.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Berkeley Letter Grade</th>
          <td>{baseGpaUnits.toFixed(2)}</td>
          <td>
            <InputUnits value={addGpaUnits} change={setAddGpaUnits} />
          </td>
          <td>{gpaUnits.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Berkeley P/NP</th>
          <td>{baseNonGpaUnits.toFixed(2)}</td>
          <td>
            <InputUnits value={addNonGpaUnits} change={setAddNonGpaUnits} />
          </td>
          <td>{nonGpaUnits.toFixed(2)}</td>
        </tr>
        <tr>
          <td colSpan="2"></td>
          <th>Total Sum</th>
          <td>{grandTotalUnits.toFixed(2)}</td>
        </tr>
      </tbody>
    </PaddedCCTable>
  );
}

const InputUnits = ({ value, change, minimum = null }) => (
  <input
    style={{ maxWidth: "50px" }}
    type="number"
    min={minimum}
    value={value.toString()}
    onChange={(e) => {
      change(e.target.value);
    }}
  />
);

InputUnits.propTypes = {
  value: PropTypes.number,
  change: PropTypes.func,
  minimum: PropTypes.oneOfType([PropTypes.number]),
};

const PaddedCCTable = styled(CCTable)`
  tbody,
  thead {
    tr {
      th {
        color: var(--mine-shaft);
      }

      th,
      td {
        padding: 4px 0;

        &:not(:first-child) {
          text-align: right;
        }
      }

      &:last-child {
        td {
          font-weight: bold;
        }
      }
    }
  }

  input[type="number"] {
    border: 1px solid silver;
    text-align: right;
    color: var(--mine-shaft);
    font-weight: bold;
  }
`;
