import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseInfo } from "data/slices/courses/courseInfo";

export function WithCourseInfo({ children, currentCourse }) {
  const dispatch = useDispatch();
  const courseId = currentCourse.course_id;

  const courseInfo = useSelector((state) => {
    const { byCourse: courses } = state;
    const { courseInfo = {} } = courses[courseId] || {};
    const { sections = [] } = courseInfo;
    return {
      ...courseInfo,
      sections: currentCourse.sections.map((section) => ({
        ...section,
        ...sections.find((sec) => sec.ccn === section.ccn),
      })),
    };
  });

  useEffect(() => {
    if (currentCourse) {
      dispatch(fetchCourseInfo(currentCourse));
    }
  }, [dispatch, currentCourse]);

  return children({ courseInfo });
}

WithCourseInfo.displayName = "WithCourseInfo";
WithCourseInfo.propTypes = {
  children: PropTypes.func,
  currentCourse: PropTypes.object,
};
