import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import CourseContext from "components/contexts/CourseContext";
import { WithCourseInfo } from "./ClassInformationCard/WithCourseInfo";
import CrossListings from "./ClassInformationCard/CrossListings";
import ClassAttributesTable from "./ClassInformationCard/ClassAttributesTable";
import FinalExams from "./ClassInformationCard/FinalExams";
import InstructionalSections from "./ClassInformationCard/InstructionalSections";
import ClassInfoNotice from "../../react/components/_academics/ClassInfoNotice";
import HairlineRule from "components/ui/HairlineRule";
import Spinner from "components/ui/Spinner";
import { courseShape } from "components/propTypes/courseShape";
import { semesterShape } from "components/propTypes/semesterShape";

export function ClassInformationCard() {
  return (
    <CourseContext.Consumer>
      {({ currentCourse, currentSemester }) => {
        if (currentCourse) {
          return (
            <WithCourseInfo currentCourse={currentCourse}>
              {({ courseInfo }) => (
                <ClassInformationCardBody
                  currentSemester={currentSemester}
                  currentCourse={currentCourse}
                  courseInfo={courseInfo}
                />
              )}
            </WithCourseInfo>
          );
        }
        return null;
      }}
    </CourseContext.Consumer>
  );
}

ClassInformationCard.displayName = "ClassInformationCard";

export function ClassInformationCardBody({
  currentCourse,
  currentSemester,
  courseInfo,
}) {
  const { loadState, sections = [] } = courseInfo;

  return (
    <Card data-testid="class-information-card">
      <CardTitle>
        <h2>Class Information</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && (
          <>
            <ClassInfoNotice termId={currentSemester.termId} />
            <h3 style={{ lineHeight: `17px` }}>{currentCourse.title}</h3>
            <ClassAttributesTable course={currentCourse} sections={sections} />
            <HairlineRule />
            <CrossListings listings={currentCourse.listings} />
            <InstructionalSections sections={sections} />
            <FinalExams sections={sections} />
          </>
        )}
      </CardBody>
    </Card>
  );
}

ClassInformationCardBody.displayName = "ClassInformationCardBody";
ClassInformationCardBody.propTypes = {
  currentCourse: courseShape,
  currentSemester: semesterShape,
  courseInfo: PropTypes.object,
};
