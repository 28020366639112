import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import VerticallySpaced from "components/ui/VerticallySpaced";
import InstructionalSection from "./InstructionalSection";
import { sectionShape } from "components/propTypes/sectionShape";

export default function InstructionalSections({ sections }) {
  return (
    <InstructionalSectionsStyles data-testid="instructional-sections">
      {sections.map((section) => (
        <InstructionalSection key={section.section_label} section={section} />
      ))}
    </InstructionalSectionsStyles>
  );
}

InstructionalSections.displayName = "InstructionalSections";
InstructionalSections.propTypes = {
  sections: PropTypes.arrayOf(sectionShape),
};

const InstructionalSectionsStyles = styled(VerticallySpaced)`
  gap: 20px;
`;
InstructionalSectionsStyles.displayName = "InstructionalSectionsStyles";
