import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "showPNPCard",
  initialState: false,
  reducers: {
    togglePNPCard: (state) => !state,
  },
});

export const { togglePNPCard } = slice.actions;
export const { reducer: showPNPCard } = slice;
