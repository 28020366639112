import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { format, parseISO } from "date-fns";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

import { DegreeStyles } from "../AcademicProfileCard/Degree";
import { DegreeIcon } from "../AcademicProfileCard/Degree";

export const DegreesConferred = () => {
  const { degrees = [] } = useAdvisingStudent(
    (student) => student.advisingStudentAcademicProfile || {}
  );

  if (degrees.length) {
    return (
      <>
        <dt>Degree Conferred</dt>
        <dd>
          {degrees.map((degree, index) => (
            <StudentSummaryDegree key={index} degree={degree} />
          ))}
        </dd>
      </>
    );
  }
  return null;
};
DegreesConferred.displayName = "DegreesConferred";

const StudentSummaryDegree = ({ degree }) => {
  const honors = degree.honors || [];
  return (
    <DegreeStyles>
      <DegreeIcon typeCode={degree.majors[0]?.typeCode} />

      <div className="Degree__body">
        <div className="Degree__description">
          {degree.description} <span>in</span> {degreeMajors(degree)}
        </div>

        {degree.designatedEmphases.length > 0 && (
          <div className="Degree__emphases">
            {degree.designatedEmphases.map((emphasis, index) => (
              <span key={index}>{emphasis.formalDescription}</span>
            ))}
          </div>
        )}
        {
          <div className="Degree__awarded-on">
            Awarded {formattedAwardDate(degree)}
          </div>
        }

        {degree.isUndergrad && (
          <Fragment>
            <div>{degree.college}</div>

            {honors.map((honor, index) => (
              <div key={index}>{honor.formalDescription}</div>
            ))}

            {degree.minors.map((minor, index) => (
              <div key={index}>{minor.formalDescription}</div>
            ))}
          </Fragment>
        )}
      </div>
    </DegreeStyles>
  );
};
StudentSummaryDegree.displayName = "StudentSummaryDegree";
StudentSummaryDegree.propTypes = {
  degree: PropTypes.object,
  index: PropTypes.number,
};

export const degreeMajors = (degree) =>
  degree.majors.map((major) => major.formalDescription).join(", ");

const formattedAwardDate = (degree) => {
  return format(parseISO(degree.dateAwarded), `${"MMM"} d, y`);
};
