import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import TransactionsCard from "components/cards/TransactionsCard";

const NgTransactionsCard = () => {
  return (
    <ReduxProvider>
      <TransactionsCard />
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("transactionsCard", react2angular(NgTransactionsCard));
