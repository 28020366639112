import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  InstructionModeHeaderCell,
  ScheduleHeaderCell,
  MeetingDetailsHeaderCell,
  IndividualSessionsHeaderCell,
} from "./IconHeaderCells";

export default function InstructionalSection({ section }) {
  const {
    section_label: label,
    ccn: courseCatalogNumber,
    schedules,
    instructionMode,
    timeConflictOverride,
    async,
    cloud,
  } = section;

  const {
    recurring: recurringSessions = [],
    oneTime: individualSessions = [],
  } = schedules;

  const meetingDetails = [async, cloud, timeConflictOverride].filter(
    (item) => item
  );

  return (
    <InstructionalSectionStyles data-testid="instructional-section">
      <h2>
        {label}
        <span> • Class # {courseCatalogNumber}</span>
      </h2>
      <SectionDetailsTable>
        <tbody>
          <tr>
            <InstructionModeHeaderCell>
              Instruction Mode
            </InstructionModeHeaderCell>
            <td>{instructionMode}</td>
          </tr>

          {meetingDetails.length > 0 && (
            <tr>
              <MeetingDetailsHeaderCell>
                Meeting Details
              </MeetingDetailsHeaderCell>
              <td>{meetingDetails.join(" • ")}</td>
            </tr>
          )}

          {recurringSessions.length > 0 && (
            <tr>
              <ScheduleHeaderCell>Schedule</ScheduleHeaderCell>
              <td>
                {recurringSessions.map((session) => {
                  const { schedule, roomNumber, buildingName } = session;

                  return (
                    <div key={schedule}>
                      {[schedule, roomNumber, buildingName]
                        .filter((item) => item)
                        .join(" - ")}
                    </div>
                  );
                })}
              </td>
            </tr>
          )}

          {individualSessions.length > 0 && (
            <tr>
              <IndividualSessionsHeaderCell>
                Individual Sess.
              </IndividualSessionsHeaderCell>
              <td>
                {individualSessions.map((session) => {
                  const { date, time, roomNumber, buildingName } = session;
                  return (
                    <div key={date}>
                      {[date, time, roomNumber, buildingName]
                        .filter((item) => item)
                        .join(" - ")}
                    </div>
                  );
                })}
              </td>
            </tr>
          )}
        </tbody>
      </SectionDetailsTable>
    </InstructionalSectionStyles>
  );
}

InstructionalSection.displayName = "InstructionalSection";
InstructionalSection.propTypes = {
  section: PropTypes.object,
};

const InstructionalSectionStyles = styled.div`
  h2 {
    font-size: 15px;
    line-height: 17px;

    span {
      font-weight: normal;
    }
  }
`;
InstructionalSectionStyles.displayName = "InstructionalSectionStyles";

const SectionDetailsTable = styled.table`
  text-align: left;

  tbody {
    tr + tr {
      th,
      td {
        padding-top: 5px;
        background-position-y: 10px;
      }
    }
  }

  tbody tr th {
    color: var(--dove-gray);
    font-weight: normal;
    white-space: nowrap;
    vertical-align: top;
    line-height: 20px;
  }
`;
SectionDetailsTable.displayName = "SectionDetailsTable";
