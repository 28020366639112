import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import WithCurrentCourse from "components/data/WithCurrentCourse";
import RosterCard from "components/cards/RosterCard";
import CourseContext from "components/contexts/CourseContext";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BreakpointProvider } from "react-socks";

const NgRosterCard = () => (
  <BreakpointProvider>
    <ReduxProvider>
      <Router>
        <Routes>
          <Route
            path="/academics/teaching-semester/:semesterId/class/:classId/roster"
            element={
              <WithCurrentCourse>
                <CourseContext.Consumer>
                  {({ currentCourse, currentSemester, loadState }) => {
                    if (loadState === "pending") {
                      return (
                        <Card>
                          <CardTitle>
                            <h2>Roster</h2>
                          </CardTitle>
                          <CardBody>
                            <Spinner message="Downloading rosters. This may take a minute for larger classes." />
                          </CardBody>
                        </Card>
                      );
                    }

                    if (currentCourse && currentSemester) {
                      return (
                        <RosterCard
                          course={currentCourse}
                          semester={currentSemester}
                        />
                      );
                    }
                  }}
                </CourseContext.Consumer>
              </WithCurrentCourse>
            }
          />
        </Routes>
      </Router>
    </ReduxProvider>
  </BreakpointProvider>
);

angular
  .module("calcentral.react")
  .component("rosterCard", react2angular(NgRosterCard));
