import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import APILink from "components/ui/APILink";
import { togglePNPCard } from "data/slices/showPNPCard";
import "./DegreeProgressLinks.scss";

export const DegreeProgressLinks = ({
  aprLink,
  aprFaqsLink,
  aprWhatIfLink,
  degreePlannerLink,
  isAdvisingStudentLookup,
}) => {
  const dispatch = useDispatch();
  const showPNPCard = useSelector((state) => state.showPNPCard);

  return (
    <>
      {aprLink && (
        <div className="linkContainer">
          <div className="linkTitle icon iconApr">Academic Progress Report</div>

          {aprLink.linkDescriptionDisplay && (
            <div className="linkSubTitle linkSubText">
              {aprLink.linkDescription + " "}
              {aprFaqsLink && <APILink link={aprFaqsLink} />}
            </div>
          )}
          <div className="linkSubText">
            <APILink link={aprLink} />
          </div>
        </div>
      )}
      {degreePlannerLink && (
        <div className="linkContainer">
          <div className="linkTitle icon iconDegreePlanner">Degree Planner</div>
          {degreePlannerLink.linkDescriptionDisplay && (
            <div className="linkSubTitle linkSubText">
              {degreePlannerLink.linkDescription}
            </div>
          )}
          <div className="linkSubText">
            <APILink link={degreePlannerLink} />
          </div>
        </div>
      )}
      {aprWhatIfLink && (
        <div className="linkContainer">
          <div className="linkTitle icon iconAprWhatIf">
            What-if Academic Progress Report
          </div>
          {aprWhatIfLink.linkDescriptionDisplay && (
            <div className="linkSubTitle linkSubText">
              {aprWhatIfLink.linkDescription}
            </div>
          )}
          <div className="linkSubText">
            <APILink link={aprWhatIfLink} />
          </div>
        </div>
      )}

      <div className="linkTitle icon iconGradeEstimator">
        1/3 Passed Grade Calculator
      </div>
      <div className="linkSubText">
        <button
          onClick={() => dispatch(togglePNPCard())}
          disabled={isAdvisingStudentLookup}
        >
          {showPNPCard ? "Hide" : "Show"} Calculator
        </button>
        {isAdvisingStudentLookup && (
          <div>
            Use View-As to see this student&apos;s Passed(P) Grade Limit and use
            the estimator.
          </div>
        )}
      </div>
    </>
  );
};

DegreeProgressLinks.displayName = "DegreeProgressLinks";
DegreeProgressLinks.propTypes = {
  aprLink: PropTypes.object,
  aprFaqsLink: PropTypes.object,
  aprWhatIfLink: PropTypes.object,
  degreePlannerLink: PropTypes.object,
  isAdvisingStudentLookup: PropTypes.bool,
};
