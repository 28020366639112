import React, { useState } from "react";
import { useUser } from "components/hooks/useUser";
import { InputTable } from "./InputTable";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { PNPContext } from "./PNPContext";
import { RatioCalculation } from "./RatioCalculation";
import { MeetsOrExceeds } from "./MeetsOrExceeds";
import { StyledMessage } from "components/ui/StyledMessage";

export function PercentageEstimator() {
  // Initial values from API
  const { baseTransferUnits, baseGpaUnits, baseNonGpaUnits } = useUser(
    ({ pnpCalculatorValues: values }) => {
      return {
        baseTransferUnits: parseFloat(values.totalTransferUnits),
        baseGpaUnits: parseFloat(values.totalGPAUnits),
        baseNonGpaUnits: parseFloat(values.totalNoGPAUnits),
      };
    }
  );

  // UI values
  const [addTransferUnits, setAddTransferUnits] = useUnitState();
  const [addGpaUnits, setAddGpaUnits] = useUnitState();
  const [addNonGpaUnits, setAddNonGpaUnits] = useUnitState();

  // Totals
  const transferUnits = baseTransferUnits + addTransferUnits;
  const gpaUnits = baseGpaUnits + addGpaUnits;
  const nonGpaUnits = baseNonGpaUnits + addNonGpaUnits;

  // Conclusion
  const grandTotalUnits = transferUnits + gpaUnits + nonGpaUnits;
  const totalBaseUnits = baseTransferUnits + baseGpaUnits + baseNonGpaUnits;

  const maxRatioBase = 120 - transferUnits;
  const applicableGpaUnits = Math.min(gpaUnits, maxRatioBase);
  const applicableNonGpaUnits = Math.min(
    nonGpaUnits,
    maxRatioBase - applicableGpaUnits
  );

  const excessNonGpaUnits = nonGpaUnits - applicableNonGpaUnits;
  const hasExcessNonGpaUnits = totalBaseUnits > 120 && excessNonGpaUnits > 0;

  const percentage = Math.max(
    0,
    Math.round(
      (applicableNonGpaUnits / (applicableGpaUnits + applicableNonGpaUnits)) *
        100
    )
  );

  return (
    <PNPContext.Provider
      value={{
        baseTransferUnits,
        baseGpaUnits,
        baseNonGpaUnits,
        addTransferUnits,
        addGpaUnits,
        addNonGpaUnits,
        setAddTransferUnits,
        setAddGpaUnits,
        setAddNonGpaUnits,
        transferUnits,
        gpaUnits,
        nonGpaUnits,
        grandTotalUnits,
        percentage,
        applicableGpaUnits,
        applicableNonGpaUnits,
        excessNonGpaUnits,
        hasExcessNonGpaUnits,
      }}
    >
      <StyledMessage style={{ marginTop: `0` }}>
        <p>
          Passed (P) graded units may account for no more than 1/3 of the total
          Berkeley units required to reach the overall 120 minimum units.
        </p>
        <p>
          Use this calculator to project your Transfer Credit, Berkeley Letter
          grade, and Berekely passed grade totals through your Expected
          Graduation Term.
        </p>
      </StyledMessage>
      <MediumSpacer />
      <InputTable />
      <MediumSpacer />
      <MeetsOrExceeds percentage={percentage} />
      <RatioCalculation />
    </PNPContext.Provider>
  );
}

function useUnitState() {
  const [units, setUnits] = useState("");
  const parsed = parseFloat(units);

  if (typeof parsed === "number" && !isNaN(parsed)) {
    return [parsed, setUnits];
  }

  return [0, setUnits];
}
