import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CCTable } from "components/ui/Table";
import { LargeSpacer } from "components/ui/VerticalSpacers";

import { useCurrentWidth } from "react-socks";
import { largeBreakpoint, mediumBreakpoint } from "config/media";
import { BMailLink } from "./BMailLink";
import { PrintOnly } from "components/ui/PrintHelpers";

export default function StudentList({
  openSeats,
  allStudents,
  columnHeaders,
  filteredStudents,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
}) {
  const sortByPath = (column) => (_event) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSortColumn(column);
      setSortDirection("ASC");
    }
  };

  const totalStudentCount = allStudents.length;
  const shownStudentCount = filteredStudents.length;

  const browserWidth = useCurrentWidth();

  const staticColumns1 = [
    { id: "last_name", label: "Name", breakpoint: undefined },
    { id: "pronouns", label: "Pronouns", breakpoint: largeBreakpoint },
    { id: "email", label: "Email", breakpoint: undefined },
    { id: "majors", label: "Majors", breakpoint: mediumBreakpoint },
  ];

  const dynamicColumns = columnHeaders.map(function (column, columnIndex) {
    return {
      id: `columns[${columnIndex}].section_number`,
      label: column.instruction_format,
      breakpoint: mediumBreakpoint,
    };
  });

  const staticColumns2 = [
    { id: "terms_in_attendance", label: "Terms", breakpoint: largeBreakpoint },
    { id: "student_id", label: "SID", breakpoint: largeBreakpoint },
    { id: "units", label: "Units", breakpoint: largeBreakpoint },
    { id: "grade_option", label: "Grade", breakpoint: largeBreakpoint },
  ];

  const columns = staticColumns1.concat(dynamicColumns).concat(staticColumns2);

  return (
    <>
      <p>
        <strong>Primary Section: </strong>
        On List: <strong>{totalStudentCount}</strong> | Available:{" "}
        <strong>{openSeats}</strong> | Shown:{" "}
        <strong>{shownStudentCount}</strong>
      </p>
      <p>
        <strong>Notes: </strong>
        Use headers to sort columns. For email links, have bMail open in the
        same browser as CalCentral. Only waitlisted students have
        &quot;Pos&quot; position numbers.
      </p>
      <LargeSpacer />

      <div style={{ border: `2px solid var(--gallery)` }}>
        <RosterTable style={{ border: `none` }}>
          <thead>
            <tr>
              {columns &&
                columns.map((column, columnIndex) => {
                  const columnBreakpoint = column.breakpoint;
                  if (columnBreakpoint && browserWidth < columnBreakpoint) {
                    return null;
                  }

                  return (
                    <th scope="col" key={columnIndex}>
                      <SortButton
                        column={column.id}
                        sortByPath={sortByPath}
                        sortDirection={sortDirection}
                        sortColumn={sortColumn}
                      >
                        <strong>{column.label}</strong>
                      </SortButton>
                      <PrintOnly>{column.label}</PrintOnly>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {filteredStudents.map((student, studentIndex) => (
              <StudentRow key={studentIndex} student={student} />
            ))}
          </tbody>
        </RosterTable>

        {filteredStudents.length === 0 && (
          <div style={{ padding: `var(--sm)` }}>
            No students found matching the above search criteria.
          </div>
        )}
      </div>
    </>
  );
}

StudentList.displayName = "StudentList";
StudentList.propTypes = {
  openSeats: PropTypes.number,
  allStudents: PropTypes.array,
  columnHeaders: PropTypes.array,
  filteredStudents: PropTypes.array,
  sortColumn: PropTypes.string,
  setSortColumn: PropTypes.func,
  sortDirection: PropTypes.string,
  setSortDirection: PropTypes.func,
};

function StudentRow({ student }) {
  const {
    last_name,
    first_name,
    pronouns,
    email,
    majors,
    columns,
    terms_in_attendance,
    student_id,
    units,
    grade_option,
  } = student;

  const browserWidth = useCurrentWidth();

  return (
    <tr>
      <td>
        {last_name}, {first_name}
      </td>
      {browserWidth >= largeBreakpoint && <td>{pronouns}</td>}
      <td>
        <BMailLink email={email}>{email}</BMailLink>
      </td>
      {browserWidth >= mediumBreakpoint && (
        <td>
          {majors.map((major, majorIndex) => (
            <div key={majorIndex}>{major}</div>
          ))}
        </td>
      )}
      {browserWidth >= mediumBreakpoint &&
        columns &&
        columns.map((column, columnIndex) => (
          <td key={columnIndex}>{column.section_number}</td>
        ))}
      {browserWidth >= largeBreakpoint && (
        <>
          <td>{terms_in_attendance}</td>
          <td>{student_id}</td>
          <td>{units}</td>
          <td>{grade_option}</td>
        </>
      )}
    </tr>
  );
}

StudentRow.displayName = "StudentRow";
StudentRow.propTypes = {
  student: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pronouns: PropTypes.string,
    email: PropTypes.string,
    majors: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(
      PropTypes.shape({ section_number: PropTypes.string })
    ),
    terms_in_attendance: PropTypes.string,
    units: PropTypes.string,
    student_id: PropTypes.string,
    grade_option: PropTypes.string,
  }),
};

function SortButton({
  children,
  column,
  sortByPath,
  sortColumn,
  sortDirection,
}) {
  return (
    <button onClick={sortByPath(column)}>
      <strong>{children} </strong>
      <SortIndicator
        column={column}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
    </button>
  );
}

SortButton.displayName = "SortButton";
SortButton.propTypes = {
  children: PropTypes.node,
  column: PropTypes.string,
  sortByPath: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
};

function SortIndicator({ column, sortColumn, sortDirection }) {
  if (column === sortColumn) {
    if (sortDirection === "ASC") {
      return <span className="fa fa-caret-down" />;
    } else if (sortDirection === "DESC") {
      return <span className="fa fa-caret-up" />;
    }
  }

  return null;
}

SortIndicator.displayName = "SortIndicator";
SortIndicator.propTypes = {
  column: PropTypes.string,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
};

const RosterTable = styled(CCTable)`
  border: 2px solid var(--gallery);

  thead {
    tr {
      td,
      th {
        padding: var(--sm);
        color: var(--white);
        background-color: var(--dove-gray);

        button {
          background: transparent;
          text-align: left;
          width: 100%;
          border: none;
          color: inherit;
        }
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        padding: var(--sm);
      }

      &:nth-child(even) {
        td,
        th {
          background: var(--gallery);
        }
      }
    }
  }
`;
