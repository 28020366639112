import React, { useState } from "react";
import PropTypes from "prop-types";
import { VisuallyHidden } from "components/ui/VisuallyHidden";
import { HoverTable } from "./HoverTable";
import { TypeSummary, GrandTotal } from "./Summary";
import { GroupItem } from "./GroupItem";

export function TypeTable({ data }) {
  const {
    amounts = [],
    grandTotal,
    types = [],
    headers = [],
  } = data;

  return (
    <HoverTable>
      {types.map(({ amounts: typeAmounts, headers: typeHeaders, items: groupItems, title: categoryTitle, total }, index) => {
        return (
          <TypeAccordian
            amounts={typeAmounts}
            categoryTitle={categoryTitle}
            headers={typeHeaders}
            groupItems={groupItems}
            key={index}
            total={total}
          />
        );
      })}
      {types && types.length > 1 && (
        <GrandTotal headers={headers} amounts={amounts} total={grandTotal} />
      )}
    </HoverTable>
  );
}

TypeTable.displayName = "TypeTable";
TypeTable.propTypes = {
  data: PropTypes.object,
};

const TypeAccordian = ({
  amounts = [],
  categoryTitle: originalCategoryTitle = "",
  headers = [],
  groupItems,
  total = 0,
}) => {
  const [expanded, onAccordionClick] = useAccordion();
  const categoryTitle = originalCategoryTitle.replace(" Items", "");

  return (
    <>
      <thead>
        <tr>
          {headers.length > 1 ? (
            <>
              <th>{categoryTitle}</th>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </>
          ) : (
            <>
              <th>{categoryTitle}</th>
              <th>{headers[0]}</th>
            </>
          )}
          <th>
            <VisuallyHidden>Controls</VisuallyHidden>
          </th>
        </tr>
      </thead>

      {groupItems &&
        groupItems.map((groupItem, index) => {
          const { subItems: categoryItems = [] } = groupItem;
          if (categoryItems.length) {
            return (
              <GroupItem
                key={index}
                item={groupItem}
                isExpanded={expanded.includes(index)}
                onClick={() => onAccordionClick(index)}
              />
            );
          }
        })}
      <TypeSummary amounts={amounts} categoryTitle={categoryTitle} headers={headers} total={total} />
    </>
  );
};

TypeAccordian.displayName = "TypeAccordian";
TypeAccordian.propTypes = {
  amounts: PropTypes.array,
  headers: PropTypes.array,
  groupItems: PropTypes.array,
  categoryTitle: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const useAccordion = () => {
  const [expanded, setExpanded] = useState([]);

  const onClick = (itemIndex) => {
    const foundAt = expanded.findIndex((index) => index === itemIndex);

    if (foundAt === -1) {
      setExpanded((expanded) => [...expanded, itemIndex]);
    } else {
      setExpanded((expanded) => expanded.toSpliced(foundAt));
    }
  };

  return [expanded, onClick];
};
