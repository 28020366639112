import styled, { css } from "styled-components";

export const ButtonAsLinkStyles = css`
  --background: transparent;

  color: var(--link-color);
  background: var(--background);

  border: none;
  padding: 0;

  font-size: inherit;
  font-weight: normal;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: var(--background);
    text-decoration: underline;
    color: var(--link-color);
  }

  &:disabled {
    background: inherit;
    pointer-events: none;
    color: var(--dusty-gray);
  }
`;

export const ButtonAsLink = styled.button`
  ${ButtonAsLinkStyles}
`;
ButtonAsLink.displayName = "ButtonAsLink";

export default ButtonAsLink;
