import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import currentUserState from "utils/currentUserState";
import { Disclosure, DisclosurePanel } from "@reach/disclosure";
import {
  BCoursesTask,
  OverdueTask,
  CompletedAgreement,
  CompletedTask,
} from "./Task";
import { OverdueTasksHeader } from "./TaskAccordion";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { BellIcon } from "components/ui/Icons";
import { taskShape } from "components/propTypes/taskShape";

import { Category } from "./Category";
import { TasksWrapper, CategoryTitle, CategoryHeader } from "./CategoryUI";
import { IncompleteBadge } from "./IncompleteDueSoonBadges";
import { DoubleChevronDisclosureButton } from "components/ui/Disclosure";
import { VisuallyHidden } from "components/ui/VisuallyHidden";

export function BCoursesTasks({ tasks = [], indices = [] }) {
  // This state will end up on a DOM element as an attribute ("hover") that
  // React doesn't understand as a boolean, so we use string values for the
  // state to prevent getting yelled at by the console
  const [hoverActive, setHoverActive] = useState("false");

  const [open, setOpen] = useState(false);

  if (tasks.length) {
    return (
      <div data-testid="bcourses-tasks-category">
        <Disclosure open={open} onChange={() => setOpen(!open)}>
          <CategoryHeader hover={hoverActive}>
            <div>
              <h3>bCourses Tasks</h3>
              {tasks.length > 0 && (
                <IncompleteBadge>
                  <BellIcon /> {tasks.length} incomplete
                </IncompleteBadge>
              )}
            </div>

            <DoubleChevronDisclosureButton
              open={open}
              setHoverActive={setHoverActive}
            >
              <VisuallyHidden>{open ? "Hide" : "View"}</VisuallyHidden>
            </DoubleChevronDisclosureButton>
          </CategoryHeader>

          <DisclosurePanel>
            <MediumSpacer />
            <TasksWrapper>
              {tasks.map((task, taskIndex) => (
                <BCoursesTask
                  key={taskIndex}
                  index={task.id}
                  task={task}
                  isOpen={indices.includes(task.index)}
                />
              ))}
            </TasksWrapper>
            <MediumSpacer />
          </DisclosurePanel>
        </Disclosure>
      </div>
    );
  }

  return null;
}

BCoursesTasks.displayName = "BCoursesTasks";
BCoursesTasks.propTypes = {
  tasks: PropTypes.arrayOf(taskShape),
  indices: PropTypes.arrayOf(PropTypes.number),
};

export function FinancialAidTasks({ tasks = [] }) {
  const byAidYear = groupByAidYear(tasks);
  const aidYears = Object.keys(byAidYear)
    .sort((a, b) => b - a)
    .map((year) => ({
      year,
      description: `${year - 1}-${year} aid year`,
      tasks: byAidYear[year],
    }));

  return (
    <>
      {aidYears.map((aidYear) => (
        <Category
          key={aidYear.year}
          tasks={aidYear.tasks}
          title="Finances Tasks"
          subtitle={aidYear.description}
          testid="financial-aid-tasks-category"
        />
      ))}
    </>
  );
}

FinancialAidTasks.displayName = "FinancialAidTasks";
FinancialAidTasks.propTypes = {
  tasks: PropTypes.arrayOf(taskShape),
  indices: PropTypes.arrayOf(PropTypes.number),
};

export function OverdueTasks({ tasks = [] }) {
  if (tasks.length) {
    return (
      <div data-testid="overdue-tasks-category">
        <OverdueTasksHeader>Overdue</OverdueTasksHeader>
        <TasksWrapper>
          {tasks.map((task, index) => (
            <OverdueTask key={index} task={task} />
          ))}
        </TasksWrapper>
      </div>
    );
  }

  return null;
}

OverdueTasks.displayName = "OverdueTasks";
OverdueTasks.propTypes = {
  tasks: PropTypes.arrayOf(taskShape),
  indices: PropTypes.arrayOf(PropTypes.number),
};

export function CompletedFinancesTasks({ tasks = [] }) {
  const byAidYear = groupByAidYear(tasks);
  const aidYears = Object.keys(byAidYear)
    .sort((a, b) => b - a)
    .map((year) => ({
      year,
      subtitle: `${year}-${year - 1}`,
      tasks: byAidYear[year],
    }));

  return (
    <>
      {aidYears.map((aidYear) => (
        <CompletedCategoryTasks
          key={aidYear.year}
          title="Finances Tasks"
          subtitle={aidYear.subtitle}
          tasks={aidYear.tasks}
        />
      ))}
    </>
  );
}

CompletedFinancesTasks.displayName = "CompletedFinancesTasks";
CompletedFinancesTasks.propTypes = {
  tasks: PropTypes.arrayOf(taskShape),
  indices: PropTypes.arrayOf(PropTypes.number),
};

export function CompletedAgreements() {
  const tasks = useSelector((state) => {
    const {
      agreements: { completedAgreements = [] },
    } = currentUserState(state);

    return completedAgreements;
  });

  if (tasks.length) {
    return (
      <div>
        <CategoryTitle>Agreements and Opt-ins</CategoryTitle>
        <TasksWrapper>
          {tasks.map((task, index) => (
            <CompletedAgreement key={index} task={task} />
          ))}
        </TasksWrapper>
      </div>
    );
  }

  return null;
}

CompletedAgreements.displayName = "CompletedAgreements";
CompletedAgreements.propTypes = {
  tasks: PropTypes.arrayOf(taskShape),
};

export function CompletedCategoryTasks({ title, subtitle, tasks = [] }) {
  if (tasks.length) {
    const sortedTasks = tasks.reverse().sort((a, b) => {
      return a.completedDate < b.completedDate ? 1 : -1;
    });

    return (
      <>
        <CategoryTitle>
          {title} <span>{subtitle}</span>
        </CategoryTitle>

        <TasksWrapper>
          {sortedTasks.map((task, index) => (
            <CompletedTask key={index} task={task} />
          ))}
        </TasksWrapper>
      </>
    );
  }

  return null;
}

CompletedCategoryTasks.displayName = "CompletedCategoryTasks";
CompletedCategoryTasks.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  tasks: PropTypes.arrayOf(taskShape),
};

function groupByAidYear(items = []) {
  return items.reduce((acc, current) => {
    acc[current.aidYear] = acc[current.aidYear]
      ? [...acc[current.aidYear], current]
      : [current];

    return acc;
  }, {});
}
