import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ButtonGroup,
  GroupedButton,
} from "components/../react/components/ButtonGroup";

import TermDropdown from "./TermDropdown";
import BillingItemSearch from "./BillingItemSearch";

import {
  BILLING_VIEW_ALL,
  BILLING_VIEW_UNPAID,
  BILLING_VIEW_PAYMENTS_AID,
} from "../billingItemViews";

const ButtonForTab = ({ tab, activeTab, setTab }) => {
  return (
    <GroupedButton active={activeTab === tab} onClick={() => setTab(tab)}>
      {tab}
    </GroupedButton>
  );
};
ButtonForTab.displayName = "ButtonForTab";
ButtonForTab.propTypes = {
  tab: PropTypes.string,
  activeTab: PropTypes.string,
  setTab: PropTypes.func,
};

const BillingItemFilters = ({
  tab,
  setTab,
  termIds,
  termId,
  setTermId,
  search,
  setSearch,
  setExpanded,
}) => {
  const changeTab = (tab) => {
    setTab(tab);
    setExpanded(null);
  };

  return (
    <BillingItemFilter>
      <ButtonGroup>
        <ButtonForTab
          tab={BILLING_VIEW_ALL}
          activeTab={tab}
          setTab={changeTab}
        />
        <ButtonForTab
          tab={BILLING_VIEW_UNPAID}
          activeTab={tab}
          setTab={changeTab}
        />
        <ButtonForTab
          tab={BILLING_VIEW_PAYMENTS_AID}
          activeTab={tab}
          setTab={changeTab}
        />
      </ButtonGroup>

      <TermDropdown termIds={termIds} value={termId} onChange={setTermId} />
      <BillingItemSearch search={search} setSearch={setSearch} />
    </BillingItemFilter>
  );
};
BillingItemFilters.displayName = "BillingItemFilters";
BillingItemFilters.propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func,
  termIds: PropTypes.array,
  termId: PropTypes.string,
  setTermId: PropTypes.func,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  setExpanded: PropTypes.func,
};

export default BillingItemFilters;

export const BillingItemFilter = styled.div`
  display: grid;
  grid-template-row: auto;
  ButtonGroup {
    width: 100%;
  }

  TermDropdown {
    width: 100%;
  }

  @media only screen and (min-width: 576px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;

    ButtonGroup {
      width: 410px;
    }

    TermDropdown {
      margin-top: 0;
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 15px;
      padding-right: 15px;
      flex-grow: 1;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 1180px) {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    ButtonGroup {
      width: 100%;
      grid-column: 1/-1;
    }

    TermDropdown {
      margin: 0;
      padding: 10px;
    }

    BillingItemSearch {
      width: 100%;
    }
  }
`;
