import React, { useState } from "react";
import PropTypes from "prop-types";

import formatCurrency from "functions/formatCurrency";
import styled from "styled-components";

const UnappliedPaymentsInfo = ({ unappliedBalance }) => {
  const [showMore, setShowMore] = useState(false);

  if (unappliedBalance > 0) {
    const linkText = showMore ? "Show less" : "What is this?";

    return (
      <UnappliedPaymentsInfoContainer>
        <div className="UnappliedPaymentsInfo__header">
          <h2>
            Unapplied Payments and Aid: {formatCurrency(unappliedBalance)}
          </h2>
          <a onClick={() => setShowMore(!showMore)}>{linkText}</a>
        </div>
        {showMore && (
          <div className="UnappliedPaymentsInfo__more">
            Unapplied payments and aid do not help lower your Total Unpaid
            Balance. Payments or aid may be unapplied because there are no
            charges to pay at this time or because they are restricted to pay
            only certain types of charges. Either part or all of the payment or
            aid amount may be unapplied.
          </div>
        )}
      </UnappliedPaymentsInfoContainer>
    );
  }

  return null;
};
UnappliedPaymentsInfo.displayName = "UnappliedPaymentsInfo";
UnappliedPaymentsInfo.propTypes = {
  unappliedBalance: PropTypes.number.isRequired,
};

export default UnappliedPaymentsInfo;
export const UnappliedPaymentsInfoContainer = styled.div`
  margin-top: 20px;

  .UnappliedPaymentsInfo__header {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    h2 {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }

    a {
      font-size: 12px;
      margin-left: 12px;
    }
  }

  .UnappliedPaymentsInfo__more {
    margin-top: 15px;
  }
`;
