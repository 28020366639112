import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format, parseISO } from "date-fns";
import { GraduationCapIcon, GrayRibbonIcon } from "components/ui/Icons";

export const Degree = ({ degree }) => {
  const honors = degree.honors || [];

  return (
    <DegreeStyles>
      <DegreeIcon typeCode={degree.majors[0]?.typeCode} />

      <div>
        <strong>{degree.description}</strong> in{" "}
        <strong>{degreeMajors(degree)}</strong>
        {degree.designatedEmphases.length > 0 && (
          <div>
            {degree.designatedEmphases.map((emphasis, index) => (
              <span key={index}>{emphasis.formalDescription}</span>
            ))}
          </div>
        )}
        <div>Awarded: {formattedAwardDate(degree)}</div>
        {degree.isUndergrad && (
          <>
            <div>{degree.college}</div>

            {honors.map((honor, index) => (
              <div key={index}>{honor.formalDescription}</div>
            ))}

            {degree.minors.map((minor, index) => (
              <div key={index}>{minor.formalDescription}</div>
            ))}
          </>
        )}
      </div>
    </DegreeStyles>
  );
};

Degree.displayName = "Degree";
Degree.propTypes = {
  degree: PropTypes.object,
};

export const DegreeStyles = styled.div`
  display: flex;
  gap: var(--sm);
`;

export function DegreeIcon({ typeCode }) {
  if (typeCode === "CRT") {
    <GrayRibbonIcon />;
  }

  return <GrayGraduationCapIcon />;
}

DegreeIcon.propTypes = {
  typeCode: PropTypes.string,
};

const GrayGraduationCapIcon = styled(GraduationCapIcon)`
  display: inline-block;
  color: var(--dove-gray);
  margin-top: 3px;
`;

const degreeMajors = (degree) =>
  degree.majors.map((major) => major.formalDescription).join(", ");

const formattedAwardDate = (degree) => {
  return format(parseISO(degree.dateAwarded), "MMMM d, y");
};
