import React from "react";
import styled from "styled-components";

export const NoDataPlaceholder = () => <span>&mdash;</span>;

export const minTermsThreshold = 2;

export const RightValueColumn = styled.td`
  text-align: right;
`;
