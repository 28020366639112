import React from "react";
import PropTypes from "prop-types";

import SortArrows from "components/../react/components/SortArrows";
import styled from "styled-components";

import { BILLING_VIEW_PAYMENTS_AID } from "../billingItemViews";

const BillingItemsHeaders = ({
  tab,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
}) => {
  return (
    <BillingItemsHeaderContainer>
      <div className="TableColumn__posted">
        <SortArrows
          label="Posted"
          column="postedOn"
          defaultOrder="DESC"
          sortBy={sortBy}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
          setSortOrder={setSortOrder}
        />
      </div>
      <div className="TableColumn__description-amount">
        <div className="TableColumn__description">Description</div>
        <div className="TableColumn__amount">
          <SortArrows
            label="Transaction Amount"
            column="amount"
            defaultOrder="DESC"
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
          />
        </div>
      </div>
      <div className="TableColumn__status">
        {tab !== BILLING_VIEW_PAYMENTS_AID && "Status"}
      </div>
      <div className="TableColumn__due">
        {tab !== BILLING_VIEW_PAYMENTS_AID && (
          <SortArrows
            label="Due"
            column="due_date"
            defaultOrder="ASC"
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
          />
        )}
      </div>
      <div></div>
    </BillingItemsHeaderContainer>
  );
};
BillingItemsHeaders.displayName = "BillingItemsHeaders";
BillingItemsHeaders.propTypes = {
  tab: PropTypes.string,
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  sortOrder: PropTypes.string,
  setSortOrder: PropTypes.func,
};

export default BillingItemsHeaders;
export const BillingItemsHeaderContainer = styled.div`
  display: none;

  @media only screen and (min-width: 576px) {
    display: grid;
    grid-template-columns: 82px 3fr 1fr 120px 10px;
    column-gap: 20px;
    padding: 10px;

    .TableColumn__description-amount {
      display: flex;
      justify-content: space-between;

      .TableColumn__description {
        margin-right: 20px;
      }
    }

    .TableColumn__due {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &.ChargeItem__due {
        flex-direction: column;
        align-items: flex-end;
        justify-content: start;
        color: var(--tundora);
        padding-right: 12px;
      }
    }

    .TableColumn__chevron {
      width: 10px;
      display: grid;
      place-items: center center;
    }

    .TableColumn__amount {
      text-align: right;
    }
      color: var(--dove-gray);
      border-bottom: 1px solid #eee;
    }
  }
`;
