import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { minTermsThreshold } from "./StudentSuccessModule";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

const GpaAverage = styled.span`
  display: inline;
  font-size: 15px;
  font-weight: normal;
`;

export default function ChartTable() {
  const { termGPA, gpa } = useAdvisingStudent(
    (student) => student.advisingStudentSuccess
  );

  const termGPAs = termGPA.toSorted(sortByKey("termId")).map(formatLast);

  const chartOptions = {
    ...defaultChartOptions,
    series: [
      {
        data: [...termGPAs],
      },
    ],
  };

  if (termGPA.length >= minTermsThreshold) {
    return (
      <>
        <h3>
          GPA Trends
          <GpaAverage>
            {" "}
            (Cumulative{" "}
            {gpa &&
              gpa[0] &&
              gpa[0].cumulativeGPA &&
              Number(gpa[0].cumulativeGPA).toPrecision(4)}
            )
          </GpaAverage>
        </h3>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </>
    );
  }

  return null;
}

ChartTable.displayName = "ChartTable";
ChartTable.propTypes = {
  termGPA: PropTypes.array,
  gpa: PropTypes.array,
};

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

const formatLast = (gpa, i, arr) => {
  if (i === arr.length - 1) {
    return {
      y: gpa.termGPA,
      dataLabels: {
        color:
          gpa.termGPA >= minTermsThreshold
            ? "var(--calypso)"
            : "var(--thunderbird)",
        enabled: true,
        style: {
          fontSize: "12px",
        },
      },
      marker: {
        enabled: true,
        fillColor:
          gpa.termGPA >= minTermsThreshold
            ? "var(--calypso)"
            : "var(--thunderbird)",
        radius: 3,
        symbol: "circle",
      },
    };
  }

  return gpa.termGPA;
};

const defaultChartOptions = {
  chart: { height: 100 },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  title: {
    text: null,
  },
  xAxis: {
    floor: 0,
    visible: false,
  },
  yAxis: {
    min: 0,
    max: 4.0,
    visible: false,
  },
};
