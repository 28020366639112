import styled from "styled-components";
import { Card } from "components/ui/Card";

export const PrintableCard = styled(Card)`
  @media print {
    overflow: visible;
    padding: 0;
    margin: 0;

    a[href]::after {
      content: none;
    }
  }
`;
