import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { MediumSpacer, LargeSpacer } from "components/ui/VerticalSpacers";
import {
  ButtonGroupTabList,
  ButtonGroupTab,
} from "components/ui/ButtonGroupTabList";

import selectArrow from "assets/images/select_arrow_20x28.svg";

import VisuallyHidden from "@reach/visually-hidden";
import Alert from "@reach/alert";

export default function Filters({
  currentSectionCCN,
  sections,
  searchText,
  setSearchText,
  setCurrentSectionCCN,
  enrollmentFilter,
  updateEnrollmentFilter,
}) {
  const [accessibleAlert, setAccessibleAlert] = useState(undefined);

  const changeTextFilter = function (textValue) {
    setAccessibleAlert("Rosters filtered by text");
    setTimeout(() => {
      setAccessibleAlert("");
    }, 2000);

    setSearchText(textValue);
  };

  const changeSectionFilter = function (sectionValue) {
    setAccessibleAlert("Rosters filtered by section");
    setTimeout(() => {
      setAccessibleAlert("");
    }, 3000);

    setCurrentSectionCCN(sectionValue);
  };

  return (
    <div>
      <div
        style={{
          display: `flex`,
          gap: `10px`,
        }}
      >
        <VisuallyHidden data-testid="accessibleAlert">
          <Alert type="assertive">{accessibleAlert}</Alert>
        </VisuallyHidden>

        <label htmlFor="searchText">
          <VisuallyHidden>Filter by Text</VisuallyHidden>
          <FilterInput
            type="text"
            id="searchText"
            value={searchText}
            placeholder="Search People"
            onChange={(e) => changeTextFilter(e.target.value)}
          />
        </label>

        <label htmlFor="section">
          <VisuallyHidden>Filter by Section</VisuallyHidden>
          <StyledSelect
            id="section"
            value={currentSectionCCN}
            onChange={(e) => changeSectionFilter(e.target.value)}
          >
            <option value="">All Sections</option>

            {sections.map((section) => (
              <option key={section.ccn} value={section.ccn}>
                {section.courseCode} {section.section_label}
              </option>
            ))}
          </StyledSelect>
        </label>

        <ButtonGroupTabList>
          <ButtonGroupTab>
            <i className="fa fa-user" /> Photos
          </ButtonGroupTab>
          <ButtonGroupTab>
            <i className="fa fa-list" /> List
          </ButtonGroupTab>
        </ButtonGroupTabList>
      </div>

      <LargeSpacer />

      <div>
        <strong>Include: </strong>{" "}
        <label style={{ marginRight: `10px` }}>
          <input
            type="radio"
            value="all"
            checked={enrollmentFilter === "all"}
            onChange={updateEnrollmentFilter}
          />{" "}
          All
        </label>
        <label style={{ marginRight: `10px` }}>
          <input
            type="radio"
            value="enrolled"
            checked={enrollmentFilter === "enrolled"}
            onChange={updateEnrollmentFilter}
          />{" "}
          Enrolled Only
        </label>
        <label>
          <input
            type="radio"
            value="waitlisted"
            name="enrollmentFilter"
            checked={enrollmentFilter === "waitlisted"}
            onChange={updateEnrollmentFilter}
          />{" "}
          Waitlisted Only
        </label>
      </div>
      <MediumSpacer />
    </div>
  );
}

Filters.displayName = "Filters";
Filters.propTypes = {
  currentSectionCCN: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  setCurrentSectionCCN: PropTypes.func,
  enrollmentFilter: PropTypes.string,
  updateEnrollmentFilter: PropTypes.func,
};

const InputStyles = css`
  border-radius: 5px;
  border: 1px solid var(--silver);
  border-radius: 5px;
  font-size: 12px;
  padding: 4px 10px 5px 8px;
`;

const FilterInput = styled.input`
  ${InputStyles}
  background: var(--white);
  width: 200px;
`;

const StyledSelect = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  ${InputStyles}
  background: url('${selectArrow}') no-repeat right center,
    linear-gradient(var(--white), var(--gallery));
  width: 200px;
`;
