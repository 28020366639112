import React from "react";
import PropTypes from "prop-types";

export default function CrossListings({ listings = [] }) {
  if (listings.length > 1) {
    const courseCodes = listings
      .map((listing) => listing.course_code)
      .join(" • ");

    return (
      <div data-testid="cross-listings" style={{ marginBottom: `20px` }}>
        <h2 style={{ fontSize: `15px`, lineHeight: `17px` }}>
          Cross-listed as
        </h2>

        <div>{courseCodes}</div>
      </div>
    );
  }

  return null;
}

CrossListings.displayName = "CrossListings";
CrossListings.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      courseCatalog: PropTypes.string,
      course_code: PropTypes.string,
      course_id: PropTypes.string,
      dept: PropTypes.string,
      dept_code: PropTypes.string,
    })
  ),
};
