import React, { useContext } from "react";
import PropTypes from "prop-types";
import WithMessage from "components/WithMessage";
import { useToggleState } from "components/hooks/useToggleState";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { PNPContext } from "./PNPContext";
import DisclosureChevron from "components/../react/components/DisclosureChevron";
import StyledMessage from "../../ui/StyledMessage";
import { MediumSpacer } from "components/ui/VerticalSpacers";

export function RatioCalculation() {
  const {
    applicableGpaUnits,
    applicableNonGpaUnits,
    percentage,
    excessNonGpaUnits,
  } = useContext(PNPContext);

  const [showCalculation, toggle] = useToggleState(false);
  const formattedPercentage = (percentage / 100).toFixed(2);
  const numerator = applicableNonGpaUnits.toFixed(2);
  const denominator = (applicableGpaUnits + applicableNonGpaUnits).toFixed(2);
  const hasExcessNonGpaUnits = parseFloat(excessNonGpaUnits) > 0;

  if (!Number.isFinite(percentage)) {
    return null;
  }

  return (
    <>
      <div style={{ textAlign: `center` }}>
        <ButtonAsLink onClick={() => toggle()} style={{ padding: `var(--lg)` }}>
          {showCalculation ? "Show Less" : "Show More"} About Percentage
          Calculation <DisclosureChevron expanded={showCalculation} />
        </ButtonAsLink>
      </div>

      {showCalculation && (
        <>
          <strong>Percentage Calculation</strong>

          <div>
            <StyledMessage>
              <p>
                {formattedPercentage} = {numerator} units / {denominator} units
              </p>
              <ul>
                <li>
                  {numerator} units: Berkeley P/NP (
                  {excessNonGpaUnits.toFixed(2)} excess units)
                </li>
                <li>
                  {denominator} units:{" "}
                  {hasExcessNonGpaUnits
                    ? "120 units - Transfer Credit"
                    : "Berkeley units"}
                </li>
              </ul>
            </StyledMessage>
            <MediumSpacer />

            <WithMessage set="32000" number="17">
              {({ description: __html }) => (
                <div dangerouslySetInnerHTML={{ __html }}></div>
              )}
            </WithMessage>
          </div>
        </>
      )}
    </>
  );
}

RatioCalculation.displayName = "RatioCalculation";
RatioCalculation.propTypes = {
  applicableGpaUnits: PropTypes.number,
  applicableNonGpaUnits: PropTypes.number,
  percentage: PropTypes.number,
  excessNonGpaUnits: PropTypes.number,
};
