import React from "react";
import PropTypes from "prop-types";
import { FormatCurrency } from "components/ui/FormatCurrency";

export const CategoryItemRow = ({ subItem: { title, amounts = [], total } }) => (
  <tr>
    <td>• {title}</td>
    {amounts.map((amount, index) => (
      <td key={index} className="amount">
        <FormatCurrency value={amount} />
      </td>
    ))}
    <td className="amount">
      <FormatCurrency value={total} />
    </td>
    <td></td>
  </tr>
);

CategoryItemRow.displayName = "CategoryItemRow";
CategoryItemRow.propTypes = {
  subItem: PropTypes.shape({
    title: PropTypes.string,
    amounts: PropTypes.array,
    total: PropTypes.number,
  }),
};
