import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/ReduxProvider";
import { ExamResultsCard } from "../cards/ExamResultsCard";

const NgExamResultsCard = () => {
  return (
    <ReduxProvider>
      <ExamResultsCard></ExamResultsCard>
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("examResultsCard", react2angular(NgExamResultsCard));
