import React from "react";
import PropTypes from "prop-types";
import VisuallyHidden from "components/ui/VisuallyHidden";

import { useParams, useMatch } from "react-router-dom";

import isPresent from "functions/isPresent";
import CustomTableStyles from "./CustomTableStyles";
import { courseShape } from "components/propTypes/courseShape";

const ClassNotesLink = ({ href }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      View Class Notes
      <VisuallyHidden>, this link opens in new window</VisuallyHidden>
    </a>
  );
};

ClassNotesLink.displayName = "ClassNotesLink";
ClassNotesLink.propTypes = {
  href: PropTypes.string,
  applicationLayer: PropTypes.string,
};

export default function CourseAttributesTable({ course, sections }) {
  const { semesterId } = useParams();
  const { role, units, slug } = course;
  const primarySection = sections.find((section) => section.is_primary_section);
  const { grading: { gradingBasis } = {} } = primarySection || {};

  const isInstructor = !!useMatch("/academics/teaching-semester");
  const isLaw = false; // TODO:
  const academicGuide = classNotesLink(semesterId, slug, primarySection);

  const orientation = tableOrientation({
    role,
    isInstructor,
    isLaw,
    gradingBasis,
    units,
  });

  if (orientation === "horizontal") {
    return (
      <CustomTableStyles data-testid="class-attributes-table">
        <thead>
          <tr>
            {role && <th>My Role</th>}
            {units && <th>Units</th>}
            {gradingBasis && <th>Grading</th>}
            {!isLaw && <th>Academic Guide</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            {role && <td>{role}</td>}
            {units && <td>{units}</td>}
            {gradingBasis && <td>{gradingBasis}</td>}
            {!isLaw && (
              <td>
                <ClassNotesLink href={academicGuide} />
              </td>
            )}
          </tr>
        </tbody>
      </CustomTableStyles>
    );
  }

  return (
    <CustomTableStyles data-testid="class-attributes-table">
      <tbody>
        <ClassAttributeRow name="My Role" value={role} />
        <ClassAttributeRow name="Units" value={units} />
        <ClassAttributeRow name="Grading" value={gradingBasis} />
        {!isLaw && (
          <ClassAttributeRow name="Academic Guide">
            <ClassNotesLink href={academicGuide} />
          </ClassAttributeRow>
        )}
      </tbody>
    </CustomTableStyles>
  );
}

CourseAttributesTable.displayName = "CourseAttributesTable";
CourseAttributesTable.propTypes = {
  course: courseShape,
  sections: PropTypes.array,
};

function classNotesLink(semesterSlug, slug, primarySection) {
  const [season, year] = semesterSlug.split("-");

  if (primarySection) {
    return `https://classes.berkeley.edu/content/${year}-${season}-${slug}-${
      primarySection.section_number
    }-${primarySection.section_label.toLowerCase().replace(" ", "-")}`;
  }
}

function tableOrientation({ role, isInstructor, isLaw, units, gradingBasis }) {
  const showClassNotesLink = !isLaw;

  // The number of items to show in the table.
  //
  // If the user is an instructor don't show units or grading basis.
  // Filter any attributes that are null.
  const countToShow = [
    role,
    isInstructor ? null : units,
    isInstructor ? null : gradingBasis,
    showClassNotesLink,
  ].filter(isPresent).length;

  if (countToShow > 2) {
    return "horizontal";
  }

  return "vertical";
}

function ClassAttributeRow({ name, value, children }) {
  if (value || children) {
    return (
      <tr>
        <th>{name}</th>
        <td>{value || children}</td>
      </tr>
    );
  }

  return null;
}

ClassAttributeRow.displayName = "ClassAttributeRow";
ClassAttributeRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
};
