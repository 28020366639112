import React from "react";
import { react2angular } from "react2angular";

import { ReduxProvider } from "components/data/ReduxProvider";
import { AcademicRecordsCard } from "../cards/AcademicRecordsCard";
import { BrowserRouter as Router} from "react-router-dom";

const NgAcademicRecordsCard = () => (
  <ReduxProvider>
    <Router>
      <AcademicRecordsCard />
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component(
    "academicRecordsCard",
    react2angular(NgAcademicRecordsCard)
  );
