import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { minTermsThreshold, RightValueColumn } from "./StudentSuccessModule";

const TermName = styled.td`
  color: var(--tundora);
  font-size: 13px;
`;

export default function TermRow({ term, index, formattedGpa }) {
  const isBelowThreshold =
    Boolean(term.termGPA) && term.termGPA < minTermsThreshold;

  return (
    <tr id={`cc-student-success-term-row-${index}`} key={index}>
      <TermName>{term.termName}</TermName>
      <RightValueColumn>
        {isBelowThreshold && (
          <>
            <i className="fa fa-exclamation-circle cc-icon-red"></i>{" "}
          </>
        )}
        {
          <span
            style={{
              color: isBelowThreshold ? "var(--thunderbird)" : "inherit",
            }}
          >
            {formattedGpa}
          </span>
        }
      </RightValueColumn>
    </tr>
  );
}

TermRow.displayName = "TermRow";
TermRow.propTypes = {
  term: PropTypes.object,
  index: PropTypes.number,
  formattedGpa: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
