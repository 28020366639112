import React from "react";
import { react2angular } from "react2angular";

import { ReduxProvider } from "components/data/ReduxProvider";
import { UndergradDegreeRequirementsCard } from "components/cards/UndergradDegreeRequirementsCard";

const NgDegreeRequirementsCard = () => (
  <ReduxProvider>
    <UndergradDegreeRequirementsCard />
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("degreeRequirementsCard", react2angular(NgDegreeRequirementsCard));
