import React from "react";
import PropTypes from "prop-types";

import dueNow from "icons/due-now.svg";
import notYetDue from "icons/not-yet-due.svg";
import exclamationCircle from "icons/exclamation-circle.svg";

import badgeStatusClassName from "./badgeStatusClassName";
import styled from "styled-components";

const DueBadge = ({ status }) => {
  const className = `Badge Badge--icon ${badgeStatusClassName(status)}`;
  return (
    <DueBadgeStyles>
      <div className={className}>{status}</div>
    </DueBadgeStyles>
  );
};
DueBadge.displayName = "DueBadge";
DueBadge.propTypes = {
  status: PropTypes.string,
};

export default DueBadge;
export const DueBadgeStyles = styled.div`
  .Badge {
    color: #333;
    border-radius: 3px;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    line-height: 21px;
    height: 20px;
    background-color: var(--gallery);
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
  }

  .Badge--icon {
    padding-left: 20px;
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 4px;
  }

  .Badge--not-due {
    background-color: var(--colonial-white);

    &.Badge--icon {
      // icon #F1A91E
      background-image: url(${notYetDue});
      background-size: 13px;
    }
  }

  .Badge--due {
    background-color: var(--karry);

    &.Badge--icon {
      background-image: url(${dueNow});
      background-size: 13px;
    }
  }

  .Badge--overdue {
    background-color: var(--cinderella);
    &.Badge--icon {
      background-image: url(${exclamationCircle});
      background-size: 13px;
    }
  }
`;
