import React from "react";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import Spinner from "components/ui/Spinner";

import AmountDueTable from "./AmountDueTable";
import ChartTable from "./ChartTable";
import TermGpaTable from "./TermGpaTable";
import UnitsTable from "./UnitsTable";

import { fetchStudentSuccess } from "data/slices/users/advisingStudentSuccess";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";
import { dispatchAdvisingStudentSlice } from "components/hooks/dispatchAdvisingStudentSlice";

export function StudentSuccessCardUI() {
  dispatchAdvisingStudentSlice(fetchStudentSuccess);

  const { loadState } = useAdvisingStudent(
    (student) => student.advisingStudentSuccess || {}
  );

  return (
    <Card data-testid="student-success-card">
      <CardTitle>
        <h2>Student Success</h2>
      </CardTitle>

      {loadState === "pending" && (
        <CardBody>
          <Spinner />
        </CardBody>
      )}

      {loadState === "success" && (
        <CardBody>
          <ChartTable />
          <TermGpaTable />
          <UnitsTable />
          <AmountDueTable />
        </CardBody>
      )}
    </Card>
  );
}
