import React from "react";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { LargeSpacer } from "components/ui/VerticalSpacers";
import { ErrorBoundary } from "components/ui/ErrorBoundary";
import {
  TabList,
  TabListButtonGroup,
  TabListButton,
  TabPanel,
} from "components/TabList";
import fetchStoredUsers from "data/actions/storedUsers";
import SearchUsersPanel from "./StudentLookupCard/SearchUsersPanel";
import StoredUsersTabPanel from "./StudentLookupCard/StoredUsersTabPanel";
import RecentUsersTabPanel from "./StudentLookupCard/RecentUsersTabPanel";

import { useRoles } from "../hooks/useRoles";
import { dispatchSlice } from "../hooks/dispatchSlice";

export function StudentLookupCard() {
  const { advisor: isAdvisor } = useRoles();

  if (isAdvisor) {
    return (
      <ErrorBoundary title="Section Overview">
        <StudentLookupCardUI />
      </ErrorBoundary>
    );
  }

  return null;
}
StudentLookupCard.displayName = "StudentLookupCard";

function StudentLookupCardUI() {
  dispatchSlice(fetchStoredUsers);

  return (
    <Card data-testid="student-lookup-card">
      <CardTitle>
        <h2>Student Lookup</h2>
      </CardTitle>
      <CardBody>
        <TabList defaultTab="search">
          <>
            <div style={{ margin: `0 30px` }}>
              <TabListButtonGroup>
                <TabListButton panelId="search" data-testid="search-tab-button">
                  Search
                </TabListButton>
                <TabListButton panelId="saved" data-testid="saved-tab-button">
                  Saved
                </TabListButton>
                <TabListButton panelId="recent" data-testid="recent-tab-button">
                  Recent
                </TabListButton>
              </TabListButtonGroup>
            </div>

            <LargeSpacer />
            <TabPanel id="search">
              <SearchUsersPanel />
            </TabPanel>
            <TabPanel id="saved">
              <StoredUsersTabPanel />
            </TabPanel>
            <TabPanel id="recent">
              <RecentUsersTabPanel />
            </TabPanel>
          </>
        </TabList>
      </CardBody>
    </Card>
  );
}
StudentLookupCardUI.displayName = "StudentLookupCardUI";
