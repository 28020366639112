import React from "react";
import PropTypes from "prop-types";
import Linkify from "react-linkify";
import styled from "styled-components";
import { format, parseISO, isThisYear } from "date-fns";
import { BaseButtonLink } from "components/ui/ButtonLink";
import AccessibleLinkContent from "components/ui/AccessibleLinkContent";

import InfoIcon from "components/ui/InfoIcon";
import Popover, { usePopover } from "components/ui/Popover";
import ButtonAsLink from "components/ui/ButtonAsLink";

import {
  CampusSolutionsIcon,
  CanvasIcon,
  ExclamationTriangle,
  OverdueIcon,
} from "components/ui/Icons";

import { shortDateIfCurrentYear, parseDate } from "functions/formatDate";
import {
  taskShape,
  completedAgreementTaskShape,
} from "components/propTypes/taskShape";
import { withinWeek } from "utils/withinWeek";

import {
  TaskAccordionItem,
  TaskAccordionPanel,
  TaskChevron,
  TaskHeader,
} from "./TaskAccordion";

import { AgreementStyles } from "./TaskAccordion";
import APILink from "components/ui/APILink";

export function Task({ task }) {
  const { title, status, statusDescription, statusDate, dueDate } = task;
  const formattedDue = dueDate && shortDateIfCurrentYear(parseDate(dueDate));

  const itemStatusPopover = usePopover();

  return (
    <TaskAccordionItem data-testid="task-item">
      <>
        <TaskHeader>
          <CampusSolutionsIcon />
          <div>
            <strong>{title}</strong>
            <div style={{ display: `flex`, justifyContent: `space-between` }}>
              <div style={{ width: `65%` }}>
                {!statusDescription &&
                  `${status} ${shortDateIfCurrentYear(parseDate(statusDate))}`}

                {statusDescription && (
                  <>
                    &nbsp;
                    <ButtonAsLink
                      onClick={(e) => {
                        itemStatusPopover.open();
                        e.stopPropagation();
                      }}
                    >
                      <InfoIcon />
                    </ButtonAsLink>
                    &nbsp;
                    {`${status} ${shortDateIfCurrentYear(
                      parseDate(statusDate)
                    )}`}
                    <div style={{ position: `relative` }}>
                      {itemStatusPopover.isOpen && (
                        <Popover
                          ref={itemStatusPopover.ref}
                          title="Status Description"
                          close={itemStatusPopover.close}
                        >
                          {statusDescription}
                        </Popover>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div>{formattedDue && `Due ${formattedDue}`}</div>
            </div>
          </div>
          <div>
            <TaskChevron />
          </div>
        </TaskHeader>

        <TaskAccordionPanel>
          <TaskDetail task={task} />
        </TaskAccordionPanel>
      </>
    </TaskAccordionItem>
  );
}

Task.displayName = "Task";
Task.propTypes = {
  task: taskShape,
};

export function CompletedTask({ task, isOpen }) {
  const { title, status, completedDate } = task;

  return (
    <TaskAccordionItem data-testid="completed-task-item">
      <>
        <TaskHeader $isComplete={true}>
          <CampusSolutionsIcon />
          <div>
            <strong>{title}</strong>
            <div>
              {status} {shortDateIfCurrentYear(parseDate(completedDate))}
            </div>
          </div>
          <div>
            <TaskChevron $isOpen={isOpen} />
          </div>
        </TaskHeader>

        <TaskAccordionPanel>
          <TaskDetail task={task} />
        </TaskAccordionPanel>
      </>
    </TaskAccordionItem>
  );
}

CompletedTask.displayName = "CompletedTask";
CompletedTask.propTypes = {
  task: taskShape,
  isOpen: PropTypes.bool,
};

export function BCoursesTask({ task, isOpen }) {
  return (
    <TaskAccordionItem data-testid="bcourses-task-item">
      <>
        <TaskHeader $isBCourses={true}>
          <CanvasIcon />
          <TaskTitle
            task={task}
            title={task.name}
            subtitle={<CourseCode courseCode={task.courseCode} />}
          />
          {task.dueTime && (
            <div style={{ textAlign: `right` }}>
              <div>Due {shortDateIfCurrentYear(parseDate(task.dueDate))}</div>
              <div
                style={{
                  fontSize: `12px`,
                  fontWeight: `700`,
                  marginTop: `-2px`,
                }}
              >
                {format(parseISO(task.dueTime), "h a")}
              </div>
            </div>
          )}
          <TaskChevron $isOpen={isOpen} />
        </TaskHeader>
        <TaskAccordionPanel>
          <TaskDetail task={task} />
        </TaskAccordionPanel>
      </>
    </TaskAccordionItem>
  );
}

BCoursesTask.displayName = "BCoursesTask";
BCoursesTask.propTypes = {
  task: taskShape,
  isOpen: PropTypes.bool,
};

const CourseCode = ({ courseCode }) => {
  return (
    <span
      style={{ color: `#999`, fontSize: `11px`, textTransform: `uppercase` }}
    >
      {courseCode}
    </span>
  );
};

CourseCode.displayName = "CourseCode";
CourseCode.propTypes = {
  courseCode: PropTypes.string,
};

export function OverdueTask({ task }) {
  const { title, dueDate, displayCategory } = task;

  return (
    <TaskAccordionItem data-testid="overdue-task-item">
      <>
        <TaskHeader $isOverdue={true}>
          <div>
            <OverdueIcon />
          </div>
          <div>
            <strong>{title}</strong>
            <div>
              {displayCategory === "bCourses" ? (
                <BTaskSubtitle task={task} />
              ) : (
                <>Due {shortDateIfCurrentYear(parseDate(dueDate))}</>
              )}
            </div>
          </div>
          <div>
            <TaskChevron />
          </div>
        </TaskHeader>
        <TaskAccordionPanel>
          <TaskDetail task={task} />
        </TaskAccordionPanel>
      </>
    </TaskAccordionItem>
  );
}

OverdueTask.displayName = "OverdueTask";
OverdueTask.propTypes = {
  task: taskShape,
  isOpen: PropTypes.bool,
};

export function CompletedAgreement({ task }) {
  return (
    <AgreementStyles data-testid="completed-agreement" $isComplete={true}>
      <CampusSolutionsIcon />
      <TaskTitle
        title={task.title}
        subtitle={`Responded ${task.response} on ${shortDateIfCurrentYear(
          parseDate(task.responseDate)
        )}`}
      />

      {task.url && (
        <APILink link={task.url}>
          {({ props, showNewWindow }) => (
            <a {...props}>
              <AccessibleLinkContent showNewWindow={showNewWindow}>
                {task.updatesAllowed ? "View/Update" : "View"}{" "}
                <i className="fa fa-arrow-right"></i>
              </AccessibleLinkContent>
            </a>
          )}
        </APILink>
      )}
    </AgreementStyles>
  );
}

CompletedAgreement.displayName = "CompletedAgreement";
CompletedAgreement.propTypes = {
  task: completedAgreementTaskShape,
};

function TaskTitle({ title, subtitle }) {
  return (
    <div style={{ flex: 1 }}>
      <strong>{title}</strong>
      {subtitle && <div>{subtitle}</div>}
    </div>
  );
}

TaskTitle.displayName = "TaskTitle";
TaskTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
};

function TaskDetail({
  task: {
    actionText,
    actionURL,
    departmentName,
    description,
    hasUpload,
    organizationName,
    uploadURL,
    url,
  },
}) {
  return (
    <TaskDetailStyles data-testid="task-detail">
      {departmentName && <strong>From {departmentName}</strong>}

      <div className="description">
        <Linkify properties={{ target: "_blank", rel: "noopener noreferrer" }}>
          {description}
        </Linkify>
      </div>

      {organizationName && (
        <div>
          <strong>Organization:</strong> {organizationName}
        </div>
      )}

      {url && <APILink link={url} text="Respond" as={RespondButtonLink} />}

      {actionURL && (
        <ActionButtonLink
          href={actionURL}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AccessibleLinkContent text={actionText} showNewWindow={true} />
        </ActionButtonLink>
      )}

      {hasUpload && (
        <APILink link={uploadURL} text="Upload" as={UploadButtonLink} />
      )}
    </TaskDetailStyles>
  );
}

TaskDetail.displayName = "TaskDetail";
TaskDetail.propTypes = {
  task: taskShape,
};

const UploadButtonLink = styled(BaseButtonLink)`
  display: inline-block;
`;

const RespondButtonLink = styled(UploadButtonLink)`
  text-align: center;
`;

const ActionButtonLink = styled(BaseButtonLink)`
  width: 100%;
  display: block;
  text-align: center;
`;

const TaskDetailStyles = styled.div`
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 30px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  .description {
    color: var(--tundora);
    hyphens: auto;
    word-wrap: break-word;
    white-space: pre-line;
  }
`;

function DueDate({ date }) {
  if (date) {
    return (
      <span>
        {withinWeek(date) && <ExclamationTriangle />} Due{" "}
        {shortDateIfCurrentYear(parseDate(date))}
      </span>
    );
  }

  return null;
}

DueDate.displayName = "DueDate";
DueDate.propTypes = {
  date: PropTypes.string,
};

const dateWithTimeAndMaybeYear = (time) => {
  if (isThisYear(time)) {
    return format(time, "MMM d, h a");
  } else {
    return format(time, "MMM d y, h a");
  }
};

const BTaskSubtitle = ({ task }) => {
  return (
    <>
      <span
        style={{ color: `#999`, fontSize: `11px`, textTransform: `uppercase` }}
      >
        {task.courseCode}
      </span>
      <br />
      Due {dateWithTimeAndMaybeYear(parseISO(task.dueTime))}
    </>
  );
};

BTaskSubtitle.displayName = "BTaskSubtitle";
BTaskSubtitle.propTypes = {
  task: taskShape,
};
