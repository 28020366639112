import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/data/ReduxProvider";
import { TextbooksCard } from "../cards/TextbooksCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const NgTextBooksCard = () => {
  return (
    <Router>
      <ReduxProvider>
        <Routes>
          <Route path="/academics/semester/:semesterId/class/:classId" element={<TextbooksCard />} />
          <Route path="/academics/teaching-semester/:semesterId/class/:classId" element={<TextbooksCard />} />
        </Routes>
      </ReduxProvider>
    </Router>
  );
};

angular
  .module("calcentral.react")
  .component("textBooks", react2angular(NgTextBooksCard));
