
import React from "react";
import { react2angular } from "react2angular";
import ReduxProvider from "components/data/ReduxProvider";
import { StudentSuccessCard } from "components/cards/StudentSuccessCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const NgAdvisorStudentSuccessCard = () => {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route path="/user/overview/:uid" element={<StudentSuccessCard />} />
        </Routes>
      </Router>
    </ReduxProvider>
  );
};

angular
  .module("calcentral.react")
  .component("advisorStudentSuccessCard", react2angular(NgAdvisorStudentSuccessCard));
