import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Tabs, TabPanel, TabPanels } from "@reach/tabs";
import { CardBody, CardTitle } from "components/ui/Card";
import Spinner from "components/ui/Spinner";

import { fetchRoster } from "data/slices/courses/roster";

import StudentList from "./RosterCard/StudentList";
import PhotoGrid from "./RosterCard/PhotoGrid";
import Filters from "./RosterCard/Filters";
import { PrintExport } from "./RosterCard/PrintExport";
import { PrintableCard } from "./RosterCard/PrintableCard";
import { NoPrint } from "components/ui/PrintHelpers";

import { filterByTextOnObject } from "./RosterCard/ngFilter";

import {
  filterEnrollment,
  filterCCN,
  sortStudents,
} from "./RosterCard/functions";
import { useParams } from "react-router-dom";
import VisuallyHidden from "../ui/VisuallyHidden";
import { useCourseData } from "../hooks/useCourseData";
import { RosterCardContext } from "./RosterCard/RosterCardContext";

export default function RosterCard({ course }) {
  const dispatch = useDispatch();
  const { classId: courseId } = useParams();

  useEffect(() => {
    if (course) {
      dispatch(fetchRoster(course));
    }
  }, [dispatch, course]);

  const {
    roster: {
      students = [],
      sections: rosterSections = [],
      loadState = "pending",
      columnHeaders,
    } = {},
  } = useCourseData();

  const { sections } = course;
  const [searchText, setSearchText] = useState("");
  const [currentSectionCCN, setCurrentSectionCCN] = useState("");
  const [enrollmentFilter, setEnrollmentFilter] = useState("all"); // or 'enrolled', 'waitlisted'

  const updateEnrollmentFilter = (e) => setEnrollmentFilter(e.target.value);

  const [sortColumn, setSortColumn] = useState("last_name");
  const [sortDirection, setSortDirection] = useState("ASC");

  const filteredStudents = [...students]
    .filter(filterByTextOnObject(searchText))
    .filter(filterEnrollment(enrollmentFilter))
    .filter(filterCCN(currentSectionCCN))
    .sort(sortStudents(sortColumn, sortDirection));

  const currentRosterSection = rosterSections.find(
    (s) => s.ccn === currentSectionCCN
  );

  const openSeats = currentRosterSection ? currentRosterSection.enroll_open : 0;

  return (
    <RosterCardContext.Provider value={{ currentSectionCCN, enrollmentFilter }}>
      <PrintableCard data-testid="roster-card">
        <CardTitle>
          <h2>Roster</h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && (
            <div style={{ textAlign: `center` }}>
              <Spinner />
              <br />
              Downloading rosters. This may take a minute for larger classes.
            </div>
          )}

          {loadState !== "pending" && (
            <Tabs>
              <NoPrint
                style={{ display: `flex`, justifyContent: `space-between` }}
              >
                <Filters
                  currentSectionCCN={currentSectionCCN}
                  sections={sections}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  setCurrentSectionCCN={setCurrentSectionCCN}
                  enrollmentFilter={enrollmentFilter}
                  updateEnrollmentFilter={updateEnrollmentFilter}
                />

                <PrintExport courseId={courseId} />
              </NoPrint>

              <hr />

              {loadState === "failure" && (
                <p>
                  <i
                    className="fa fa-exclamation-triangle"
                    style={{ color: `var(--thunderbird)` }}
                  ></i>{" "}
                  You must be a teacher in this bCourses course to view official
                  student rosters.
                </p>
              )}

              {loadState === "success" && !sections.length && (
                <p>
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ color: `var(--buttercup)` }}
                  ></i>{" "}
                  There are no currently maintained official sections in this
                  course site.
                </p>
              )}

              {loadState === "success" &&
                !students.length &&
                sections.length && (
                  <p>
                    <i
                      className="fa fa-exclamation-circle cc-icon-gold"
                      style={{ color: `var(--buttercup)` }}
                    ></i>{" "}
                    Students have not yet signed up for this class.
                  </p>
                )}

              {loadState === "success" && (
                <>
                  <VisuallyHidden>
                    <h2>
                      {course.title} -{" "}
                      {currentRosterSection
                        ? currentRosterSection.name
                        : "All Sections"}
                      {searchText && ` - Matching '${searchText}'`}
                    </h2>
                  </VisuallyHidden>

                  <TabPanels>
                    <TabPanel>
                      <PhotoGrid
                        students={filteredStudents}
                        courseId={courseId}
                      />
                    </TabPanel>
                    <TabPanel>
                      <StudentList
                        allStudents={students}
                        openSeats={openSeats}
                        columnHeaders={columnHeaders}
                        filteredStudents={filteredStudents}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                      />
                    </TabPanel>
                  </TabPanels>
                </>
              )}
            </Tabs>
          )}
        </CardBody>
      </PrintableCard>
    </RosterCardContext.Provider>
  );
}

RosterCard.displayName = "RosterCard";
RosterCard.propTypes = {
  course: PropTypes.shape({
    title: PropTypes.string,
    sections: PropTypes.array,
  }),
};
