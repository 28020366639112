import React from "react";
import PropTypes from "prop-types";
import { RedWarning } from "components/ui/Icons";
import { GreenCheck } from "components/ui/Icons";

export function MeetsOrExceeds({ percentage }) {
  const exceedsLimit = percentage > 33;
  const background = exceedsLimit ? `cinderella` : `granny-apple`;

  if (!Number.isFinite(percentage)) {
    return null;
  }

  return (
    <div
      style={{
        background: `var(--${background})`,
        display: `flex`,
      }}
    >
      <div style={{ padding: `var(--sm)` }}>
        {exceedsLimit ? <RedWarning /> : <GreenCheck />}
      </div>
      <div style={{ padding: `var(--sm)` }}>
        <p style={{ marginBottom: `var(--md)`, marginTop: `0` }}>
          <strong>
            Your Passed graded units account for {percentage}% of your total
            units required to reach 120 minimum units.
          </strong>
        </p>

        {percentage === 0 ? (
          <div>
            Although you may have earned Passed grades, your Berkeley Letter
            Grade total is sufficient enough to not exceed the 1/3 Passed Grade
            Limit.
          </div>
        ) : (
          <div>You cannot exceed 33% by the time of graduation.</div>
        )}
      </div>
    </div>
  );
}

MeetsOrExceeds.displayName = "MeetsOrExceeds";
MeetsOrExceeds.propTypes = {
  percentage: PropTypes.number,
};
