import React, { useState } from "react";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { fetchFinaidCostOfAttendance } from "data/slices/users/finaid/finaidCostOfAttendance";
import { Spinner } from "components/ui/Spinner";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { MediumSpacer } from "components/ui/VerticalSpacers";
import { TypeTable } from "./CostOfAttendanceCard/TypeTable";
import { useFinaidYear } from "components/hooks/useFinaidYear";
import { dispatchUserFinaidSlice } from "components/hooks/dispatchUserFinaidSlice";
import { StyledMessage } from "components/ui/StyledMessage";
import { ErrorBoundary } from "components/ui/ErrorBoundary";
import { LargeSpacer } from "components/ui/VerticalSpacers";

export function CostOfAttendanceCard() {
  return (
    <ErrorBoundary title="Estimated Cost of Attendance">
      <CostOfAttendanceCardUI />
    </ErrorBoundary>
  );
}
CostOfAttendanceCard.displayName = "CostOfAttendanceCard";

function CostOfAttendanceCardUI() {
  dispatchUserFinaidSlice(fetchFinaidCostOfAttendance);

  const { loadState } = useFinaidYear(
    (finaid) => finaid.finaidCostOfAttendance || {}
  );

  return (
    <Card data-testid="finaid-estimated-cost-of-attendance-card">
      <CardTitle>
        <h2>Estimated Cost of Attendance</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "success" && <CostOfAttendanceBody />}
      </CardBody>
    </Card>
  );
}
CostOfAttendanceCardUI.displayName = "CostOfAttendanceCardUI";

function CostOfAttendanceBody() {
  const [fullYearOrSemester, setFullYearOrSemester] = useState("fullyear");

  const {
    coa: { fullyear: fullYearData = {}, semester: semesterData = {} } = {},
    coaInstructionsMessage: { descrlong: message } = {},
    dataPresent = false,
    noDataMessage: { descrlong: noDataPresentMessage } = {},
  } = useFinaidYear((finaid) => finaid.finaidCostOfAttendance || {});

  const toggleView = () => {
    setFullYearOrSemester(
      fullYearOrSemester === "semester" ? "fullyear" : "semester"
    );
  };

  const currentDataSet =
    fullYearOrSemester === "semester" ? semesterData : fullYearData;

  return (
    <>
      {!dataPresent && noDataPresentMessage && (
        <div>{noDataPresentMessage}</div>
      )}

      {dataPresent && message && (
        <>
          <StyledMessage
            dangerouslySetInnerHTML={{ __html: message }}
            style={{ margin: `0` }}
          />
          <LargeSpacer />
        </>
      )}

      {dataPresent && currentDataSet.types && !!currentDataSet.types.length && (
        <>
          <ButtonAsLink onClick={toggleView}>
            {fullYearOrSemester === "semester"
              ? "View Annual Amount"
              : "View Term Amount"}
          </ButtonAsLink>
          <MediumSpacer />
          <TypeTable data={currentDataSet} />
        </>
      )}
    </>
  );
}
CostOfAttendanceBody.displayName = "CostOfAttendanceBody";
