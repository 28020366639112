import styled, { css } from "styled-components";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "components/ui/Accordion";

import chevronDown from "icons/chevron-down.svg";

const HeaderStyles = css`
  padding: 10px 0;
  display: grid;
  line-height: 1.5;

  grid-template-columns: 30px 2fr 20px; // the third column is not always present
  font-size: 13px;

  .title {
    font-weight: 700;
  }

  > *:nth-child(3) {
    align-items: end;
    justify-self: end;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${({ $isOverdue }) => $isOverdue && `color: var(--thunderbird);`}
  ${({ $isComplete }) => $isComplete && `grid-template-columns: 30px 2fr 1fr;`}
  ${({ $isBCourses }) =>
    $isBCourses && `grid-template-columns: 30px 2fr 1fr 20px;`}
`;

export const TaskHeader = styled(AccordionButton)`
  ${HeaderStyles}
`;

export const TaskChevron = styled.div`
  width: 20px;
  height: 20px;
  border-width: 0;
  border-radius: 10px;
  background: url("${chevronDown}") center center / auto no-repeat;

  &:hover,
  &:focus {
    background-color: var(--link-water);
  }
`;

// Agreements are not expandable accordion items, but they are styled
export const AgreementStyles = styled.div`
  ${HeaderStyles}

  // Give them the same hover effect as a button
  &:hover,
  &:focus {
    background-color: var(--foam);
  }
`;

export const TaskAccordionItem = styled(AccordionItem)`
  &[data-reach-accordion-item][data-state="open"] ${TaskChevron} {
    transform: scaleY(-1);
  }

  & + & {
    border-top: 1px solid var(--gallery);
  }

  &:hover,
  &:focus {
    background-color: var(--foam);
  }
`;

export const TaskAccordionPanel = AccordionPanel;

export const OverdueTasksHeader = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0;
  color: var(--thunderbird);
  font-size: 16px;
  border-bottom: 1px solid var(--gallery);
`;
