import styled from "styled-components";
import { CCTable } from "components/ui/Table";

export const HoverTable = styled(CCTable)`
  thead,
  tbody {
    tr {
      th,
      td {
        padding-left: 4px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  thead {
    tr {
      th {
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }
  }

  .amount {
    text-align: right;
  }

  .total {
    text-align: right;
  }

  .controls {
    text-align: right;
    width: 16px;
    padding-left: 5px;
  }

  tbody.hoverFocus:hover {
    tr {
      td {
        background-color: var(--foam);
      }
    }
  }

  tbody.summary {
    tr:first-child {
      td {
        border-top: 1px solid var(--silver);
      }
    }

    tr.titleRow {
      td {
        color: var(--dusty-gray);
        font-size: 12px;
      }
    }
  }

  tbody {
    tr {
      td.amount,
      td.total {
        padding-left: 5px;
      }
    }
  }

  tbody + thead {
    tr th {
      padding-top: var(--lg);
    }
  }
`;
