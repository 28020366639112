import React from "react";
import PropTypes from "prop-types";

import DisclosureChevron from "components/../react/components/DisclosureChevron";
import styled from "styled-components";

const ShowMore = ({ expanded, onClick }) => (
  <ShowMoreContainer onClick={onClick}>
    <span className="ShowMore__message">
      {expanded ? "Show Less" : "Show More"}
    </span>
    <DisclosureChevron expanded={expanded} />
  </ShowMoreContainer>
);
ShowMore.displayName = "ShowMore";
ShowMore.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ShowMore;
export const ShowMoreContainer = styled.div`
  padding: 10px 10px 0;
  color: var(--denim);
  display: flex;
  justify-content: center;
  cursor: pointer;

  .ShowMore__message {
    margin-right: 5px;
  }
`;
