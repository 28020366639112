import React from "react";
import PropTypes from "prop-types";

import formatCurrency from "functions/formatCurrency";
import formatDate from "functions/formatDate";
import timelineTopCap from "icons/timeline-top-cap.svg";
import timelineBottomCap from "icons/timeline-bottom-cap.svg";
import timelineCenterBlue from "icons/timeline-center-blue.svg";
import timelineCenter from "icons/timeline-center.svg";
import timelineEmpty from "icons/timeline-empty.svg";
import styled from "styled-components";

import { chargeTypes } from "../types";

import { parseISO } from "date-fns";

const directionForAdjustment = (amount, itemType) => {
  if (chargeTypes.has(itemType)) {
    return amount > 0 ? "Increased by" : "Decreased by";
  } else {
    return amount < 0 ? "Increased by" : "Decreased by";
  }
};

export const GenericAdjustment = ({ className, description, date }) => {
  return (
    <ItemAdjustmentStyles className={`ItemAdjustment ${className}`}>
      <div className="ItemAdjustment__date">{formatDate(date)}</div>
      <div className="ItemAdjustment__description">{description}</div>
    </ItemAdjustmentStyles>
  );
};
GenericAdjustment.displayName = "GenericAdjustment";
GenericAdjustment.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

const ItemAdjustment = ({ adjustment, itemType }) => {
  const date = parseISO(adjustment.posted);
  const direction = directionForAdjustment(adjustment.amount, itemType);
  const amount = Math.abs(adjustment.amount);
  const description = `${direction} ${formatCurrency(amount)}`;

  return (
    <ItemAdjustmentStyles className={`ItemAdjustment ItemAdjustment--change`}>
      <div className="ItemAdjustment__date">{formatDate(date)}</div>
      <div className="ItemAdjustment__description">{description}</div>
    </ItemAdjustmentStyles>
  );
};
ItemAdjustment.displayName = "ItemAdjustment";
ItemAdjustment.propTypes = {
  adjustment: PropTypes.object,
  itemType: PropTypes.string,
};

export default ItemAdjustment;
export const ItemAdjustmentStyles = styled.div`
  display: flex;
  background-image: url(${timelineCenter});
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 15px;
  margin-left: -4px;
  line-height: 23px;

  .ItemAdjustment__date {
    width: 114px;
  }

  &.ItemAdjustment--change {
    color: var(--dove-gray);

    .ItemAdjustment__description {
      font-style: italic;
    }
  }

  &.ItemAdjustment--last {
    color: var(--dove-gray);
  }

  &.ItemAdjustment--show-more {
    background-image: url(${timelineCenterBlue});
    color: var(--denim);
    cursor: pointer;
  }

  &.ItemAdjustment--too-many {
    background-image: url(${timelineEmpty});
    color: var(--dove-gray);
  }

  &:first-child {
    background-image: url(${timelineTopCap});
    background-position-y: 6px;
  }

  &:last-child {
    background-position-y: -10px;
    background-image: url(${timelineBottomCap});
  }
`;
