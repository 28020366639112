import React from "react";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";

import { fetchUndergradDegreeProgress } from "data/slices/users/undergradDegreeProgress";
import { DegreeProgressLinks } from "./UndergradDegreeRequirementsCard/DegreeProgressLinks";
import { useUser } from "../hooks/useUser";
import { dispatchUserSlice } from "../hooks/dispatchUserSlice";
import { useRoles } from "../hooks/useRoles";
import { useFeatures } from "../hooks/useFeatures";
import { useHistoricAcademicRoles } from "../hooks/useAcademicRoles";
import { ErrorBoundary } from "components/ui/ErrorBoundary";

export function UndergradDegreeRequirementsCard() {
  const { undergrad } = useRoles();
  const { csDegreeProgressUgrdStudent } = useFeatures();
  const { degreeSeeking } = useHistoricAcademicRoles();
  const showCard = csDegreeProgressUgrdStudent && undergrad && degreeSeeking;

  if (showCard) {
    return (
      <ErrorBoundary title="Degree Requirements">
        <UndergradDegreeRequirementsCardUI />
      </ErrorBoundary>
    );
  }

  return null;
}

UndergradDegreeRequirementsCard.displayName = "UndergradDegreeRequirementsCard";

function UndergradDegreeRequirementsCardUI() {
  dispatchUserSlice(fetchUndergradDegreeProgress);

  const {
    loadState,
    feed: {
      links: {
        academicProgressReport,
        academicProgressReportFaqs,
        academicProgressReportWhatIf,
        degreePlanner,
      } = {},
    } = {},
  } = useUser((user) => user.undergradDegreeProgress);

  return (
    <Card data-testid="ugrd-degree-requirements-card">
      <CardTitle>
        <h2>Degree Requirements</h2>
      </CardTitle>
      <CardBody>
        {loadState === "pending" && <Spinner />}
        {loadState === "failure" && (
          <p>
            There was an error retrieving undergraduate degree progress data.
          </p>
        )}

        {loadState === "success" && (
          <DegreeProgressLinks
            aprLink={academicProgressReport}
            aprFaqsLink={academicProgressReportFaqs}
            aprWhatIfLink={academicProgressReportWhatIf}
            degreePlannerLink={degreePlanner}
          />
        )}
      </CardBody>
    </Card>
  );
}

UndergradDegreeRequirementsCardUI.displayName =
  "UndergradDegreeRequirementsCardUI";
