import React from "react";
import { FormatCurrency } from "components/ui/FormatCurrency";

import { CCTable } from "components/ui/Table";
import { NoDataPlaceholder } from "./StudentSuccessModule";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export default function AmountDueTable() {
  const { outstandingBalance } = useAdvisingStudent(
    (student) => student.advisingStudentSuccess
  );

  return (
    <CCTable>
      <thead>
        <tr>
          <th width="84%">
            <h3>Finances</h3>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Amount Due Now</td>
          <td>
            {outstandingBalance ? (
              <FormatCurrency value={outstandingBalance} precision={2} />
            ) : (
              <NoDataPlaceholder />
            )}
          </td>
        </tr>
      </tbody>
    </CCTable>
  );
}

AmountDueTable.displayName = "AmountDueTable";
