import React from "react";
import PropTypes from "prop-types";

export const BMailLink = ({ email, children }) => (
  <a
    href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${encodeURIComponent(
      email
    )}`}
  >
    {children}
  </a>
);

BMailLink.propTypes = {
  email: PropTypes.string,
  children: PropTypes.node,
};
