import React from "react";
import PropTypes from "prop-types";

import { termFromId } from "components/../react/helpers/terms";
import formatCurrency from "functions/formatCurrency";
import styled from "styled-components";

const MoreDetails = ({
  item: { term_id: termId, transaction_number: transactionNumber, balance },
}) => {
  const term = termFromId(termId);
  const unapplied = Math.abs(balance);

  return (
    <UlMoreDetails>
      {transactionNumber && (
        <li>
          <strong>Transaction Number:</strong> {transactionNumber}
        </li>
      )}

      {term && (
        <li>
          <strong>Term:</strong> {`${term.semester} ${term.year}`}
        </li>
      )}

      {unapplied > 0 && (
        <li>
          <strong>Unapplied Amount:</strong> {formatCurrency(unapplied)}
        </li>
      )}
    </UlMoreDetails>
  );
};
MoreDetails.displayName = "MoreDetails";
MoreDetails.propTypes = {
  item: PropTypes.object,
};

export default MoreDetails;
export const UlMoreDetails = styled.ul`
  li {
    margin-top: 20px;
    margin-left: 40px;
  }

  li + li {
    margin-top: 5px;
  }

  list-style-type: none;
`;
