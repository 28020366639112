import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import LightText from "../../ui/LightText";

export function TeachingCoursesBySemester({ semester }) {
  return (
    <div data-testid="teaching-course-by-semester">
      <h3>
        <Link key={semester.name} to={`/academics/semester/${semester.slug}`}>
          {semester.name}
        </Link>
      </h3>
      {semester.classes.map((course) => (
        <div key={course.course_code}>
          <b>
            <Link to={course.url}>{course.course_code}</Link>
          </b>
          <LightText>{course.title}</LightText>
        </div>
      ))}
    </div>
  );
}

TeachingCoursesBySemester.displayName = "TeachingCoursesBySemester";
TeachingCoursesBySemester.propTypes = {
  semester: PropTypes.shape({
    name: PropTypes.string.isRequired, // Name of the semester
    slug: PropTypes.string.isRequired, // URL slug for the semester
    classes: PropTypes.arrayOf(
      PropTypes.shape({
        course_code: PropTypes.string.isRequired, // Unique course code
        url: PropTypes.string.isRequired, // Course URL
        title: PropTypes.string.isRequired, // Course title
      })
    ).isRequired,
  }).isRequired,
};
