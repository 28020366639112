import React from "react";
import PropTypes from "prop-types";

import { CCTable } from "components/ui/Table";
import GpaTemplate from "./GpaTemplate";
import UnitsSubHeader from "./UnitsSubHeader";
import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";
import { RightValueColumn } from "./StudentSuccessModule";
export default function UnitsTable() {
  const { units } = useAdvisingStudent(
    (student) => student.advisingStudentSuccess
  );

  const hasLawUnits = units && units.totalLawUnits;

  if (units) {
    return (
      <CCTable style={{ marginBottom: "12px" }}>
        <thead>
          <tr>
            <th width="84%">
              <h3>
                Units
                <UnitsSubHeader units={units} />
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {Boolean(hasLawUnits) && (
            <tr>
              <td>Law Units</td>
              <RightValueColumn>
                <span>{units.totalLawUnits.toFixed(1)}</span>
              </RightValueColumn>
            </tr>
          )}
          <tr>
            <td>Units for GPA</td>
            <RightValueColumn>
              <GpaTemplate units={units} lookupKey={"totalUnitsForGPA"} />
            </RightValueColumn>
          </tr>
          {Boolean(units.totalUnitsPassedNotForGPA) && (
            <tr>
              <td>Units not for GPA</td>
              <RightValueColumn>
                <GpaTemplate
                  units={units}
                  lookupKey={"totalUnitsPassedNotForGPA"}
                />
              </RightValueColumn>
            </tr>
          )}
          {Boolean(units.totalTransferAndTestingUnits) && (
            <tr>
              <td>Transfer Units</td>
              <RightValueColumn>
                <GpaTemplate
                  units={units}
                  lookupKey={"totalTransferAndTestingUnits"}
                />
              </RightValueColumn>
            </tr>
          )}
        </tbody>
      </CCTable>
    );
  }

  return null;
}

UnitsTable.displayName = "UnitsTable";
UnitsTable.propTypes = { units: PropTypes.object };
