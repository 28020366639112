import PropTypes from "prop-types";
import { sectionShape } from "./sectionShape";

export const courseShape = PropTypes.shape({
  sections: PropTypes.arrayOf(sectionShape),
  term_id: PropTypes.string,
  cs_course_id: PropTypes.string,
  title: PropTypes.string,
  listings: PropTypes.array,
  site_url: PropTypes.string,
  name: PropTypes.string,
  shortDescription: PropTypes.string,
});
