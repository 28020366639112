import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Card, CardBody, CardTitle } from "components/ui/Card";
import currentUserState from "utils/currentUserState";

import { fetchExamResults } from "data/slices/users/examResults";
import { CCTable } from "components/ui/Table";

export function ExamResultsCard() {
  const dispatch = useDispatch();

  const { currentUID, exams } = useSelector((state) => {
    const {
      currentUID,
      examResults: { exams: exams = [] },
    } = currentUserState(state);
    return {
      currentUID,
      exams,
    };
  });

  useEffect(() => {
    if (currentUID) {
      dispatch(fetchExamResults(currentUID));
    }
  }, [currentUID]);

  function strToDate(taken) {
    var date = new Date(taken);
    return Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
    }).format(date);
  }

  const sortedExams = exams.toSorted((a, b) => {
    return (
      a.id.localeCompare(b.id) ||
      a.descr.localeCompare(b.descr) ||
      a.taken.localeCompare(b.taken)
    );
  });

  return (
    <Card data-testid="exam-results-card">
      <CardTitle>
        <h2>Exam Results</h2>
      </CardTitle>
      <CardBody>
        <p>
          Below are exam scores submitted during the admission process, or taken
          on the Berkeley campus for Entry Level Writing, Quantitative
          Reasoning, or Language Proficiency.
        </p>
        <div>
          <CCTableBorder>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Exam ID&#42;</th>
                <th style={{ width: "65%" }}>Exam Name</th>
                <th style={{ textAlign: `right` }}>Score</th>
                <th style={{ textAlign: `right` }}>Date Taken</th>
              </tr>
            </thead>
            <tbody>
              {sortedExams.map(({ id, descr, score, taken }, idx) => (
                <tr key={idx}>
                  <td>{id}</td>
                  <td>{descr}</td>
                  <td style={{ textAlign: `right` }}>{score.toFixed(2)}</td>
                  <td style={{ textAlign: `right` }}>{strToDate(taken)}</td>
                </tr>
              ))}
            </tbody>
          </CCTableBorder>
        </div>
        <p>
          &#42; For ALEVEL exams: A Grades = Score 1.00, B Grades = 2.00, C
          Grades = Score 3.00
        </p>
        <p>
          Transfer Course and Test Credit are posted to your Transfer Credit
          Report.
        </p>
      </CardBody>
    </Card>
  );
}
ExamResultsCard.displayName = "ExamResultsCard";

export const CCTableBorder = styled(CCTable)`
  tr {
    td {
      border-top: 1px solid var(--gallery);
      padding: 1px 0 1px 0;
      font-size: var(--base-font-size);
    }
  }
  tr:last-child {
    td {
      border-bottom: 1px solid var(--gallery);
    }
  }
`;
CCTableBorder.displayName = "CCTableBorder";
