import React from "react";
import { react2angular } from "react2angular";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReduxProvider from "components/ReduxProvider";
import { ClassInformationCard } from "components/cards/ClassInformationCard";
import WithCurrentCourse from "components/data/WithCurrentCourse";
import CourseContext from "components/contexts/CourseContext";

const NgClassInformationCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/teaching-semester/:semesterId/class/:classId"
          element={
            <WithCurrentCourse>
              <CourseContext.Consumer>
                {({ currentCourse, currentSemester, loadState }) => (
                  <ClassInformationCard
                    currentCourse={currentCourse}
                    currentSemester={currentSemester}
                    loadState={loadState}
                  />
                )}
              </CourseContext.Consumer>
            </WithCurrentCourse>
          }
        />
        
        <Route
          path="academics/semester/:semesterId/class/:classId"
          element={
            <WithCurrentCourse>
              <CourseContext.Consumer>
                {({ currentCourse, currentSemester, loadState }) => (
                  <ClassInformationCard
                    currentCourse={currentCourse}
                    currentSemester={currentSemester}
                    loadState={loadState}
                  />
                )}
              </CourseContext.Consumer>
            </WithCurrentCourse>
          }
        />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("classInformationCard", react2angular(NgClassInformationCard));