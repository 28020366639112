import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import TeachingCoursesCard from "../cards/TeachingCoursesCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const NgTeachingCoursesCard = () => (
  <ReduxProvider>
    <Router>
      <Routes>
        <Route
          path="academics/semester/:semesterId/*"
          element={<TeachingCoursesCard />}
        />
        <Route path="academics" element={<TeachingCoursesCard />} />
      </Routes>
    </Router>
  </ReduxProvider>
);

angular
  .module("calcentral.react")
  .component("teachingCoursesCard", react2angular(NgTeachingCoursesCard));
