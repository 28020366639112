import React, { useState } from "react";
import PropTypes from "prop-types";

import unavailable from "assets/images/photo_unavailable_official_72x96.svg";

export function RosterPhoto({
  student: { first_name, last_name, photo, waitlist_position } = {},
}) {
  const [error, setError] = useState(false);

  if (error || waitlist_position !== undefined) {
    return (
      <img
        src={unavailable}
        width="72"
        height="96"
        alt="No Official Photo is Available"
      />
    );
  }

  if (photo) {
    return (
      <img
        src={photo}
        onError={() => setError(true)}
        width="72"
        height="96"
        alt={`Photo of ${first_name} ${last_name}`}
      />
    );
  }

  return null;
}

RosterPhoto.displayName = "RosterPhoto";
RosterPhoto.propTypes = {
  student: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    photo: PropTypes.string,
  }),
};
