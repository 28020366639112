import styled from "styled-components";
import { CCTable } from "components/ui/Table";

const CustomTableStyles = styled(CCTable)`
  thead tr th,
  tbody tr th {
    font-weight: normal;
  }
`;

CustomTableStyles.displayName = "CustomTableStyles";
export default CustomTableStyles;
