import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { CampusLinksCard } from "components/cards/CampusLinksCard";

function NgCampusLinksCard() {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route path="/campus" element={<CampusLinksCard />} />
          <Route path="/campus/:categoryId" element={<CampusLinksCard />} />
        </Routes>
      </Router>
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("campusLinksCard", react2angular(NgCampusLinksCard));
