import styled from "styled-components";
import { TabList, Tab } from "@reach/tabs";

export const ButtonGroupTabList = styled(TabList)`
  display: inline-block;
  font-size: 11px;
`;
ButtonGroupTabList.displayName = "ButtonGroupTabList";

export const ButtonGroupTab = styled(Tab)`
  border: 1px solid var(--silver);

  &:first-child {
    border-top-left-radius: var(--small-border-radius);
    border-bottom-left-radius: var(--small-border-radius);
  }

  &:last-child {
    border-top-right-radius: var(--small-border-radius);
    border-bottom-right-radius: var(--small-border-radius);
  }

  &[aria-selected="true"] {
    background: var(--foam);
    color: var(--denim);
    border-color: var(--denim);
  }
`;
ButtonGroupTab.displayName = "ButtonGroupTab";
