import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import VisuallyHidden from "components/ui/VisuallyHidden";
import { NoPrint } from "components/ui/PrintHelpers";
import { RosterPhoto } from "./RosterPhoto";
import { BMailLink } from "./BMailLink";

export default function PhotoGrid({ students }) {
  return (
    <StudentGrid>
      {students.map((student, studentIndex) => (
        <StudentCard key={studentIndex} student={student} />
      ))}
    </StudentGrid>
  );
}

PhotoGrid.displayName = "PhotoGrid";
PhotoGrid.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      student_id: PropTypes.string,
    })
  ),
};

const StudentGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StudentCardStyles = styled.div`
  padding: 5px;
  text-align: center;
  width: 175px;
`;

function StudentCard({ student, courseId = "embedded" }) {
  const {
    last_name,
    first_name,
    pronouns,
    terms_in_attendance,
    student_id,
    profile_url,
    login_id,
    email,
  } = student;

  const context = "campus";
  const origin = window.location.origin;

  const photoURL = profile_url
    ? profile_url
    : `${origin}/${context}/${courseId}/profile/${login_id}`;

  return (
    <StudentCardStyles>
      <Link to={photoURL} target="_top">
        <RosterPhoto student={student} />
      </Link>

      <div>
        <BMailLink email={email}>
          {first_name}
          <br />
          <strong>{last_name}</strong>
        </BMailLink>
      </div>
      <div style={{ fontSize: "11px", height: "20px" }}>
        {pronouns && "Pronouns: " + pronouns}
      </div>
      <NoPrint>
        <VisuallyHidden>Student ID: </VisuallyHidden>
        {student_id}
      </NoPrint>
      <NoPrint>Terms: {terms_in_attendance}</NoPrint>
      <NoPrint>{student.majors.join(", ")}</NoPrint>
    </StudentCardStyles>
  );
}

StudentCard.displayName = "StudentCard";
StudentCard.propTypes = {
  student: PropTypes.object,
  courseId: PropTypes.string,
};
