import styled from "styled-components";

export const NoPrint = styled.div`
  @media print {
    display: none !important;
  }
`;

export const PrintOnly = styled.div`
  display: none;

  @media print {
    display: inherit;
  }
`;
