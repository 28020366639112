import styled from "styled-components";
import {
  BaseButtonStyles,
  BlueButtonStyles,
  ModernBlueButtonStyles,
  ModernButtonStyles,
} from "./BaseButtonStyles";

export const BaseButton = styled.button`
  ${BaseButtonStyles}
`;

export const BlueButton = styled(BaseButton)`
  ${BlueButtonStyles}
`;

export const ModernButton = styled(BaseButton)`
  ${ModernButtonStyles}
`;

export const ModernBlueButton = styled(BlueButton)`
  ${ModernBlueButtonStyles}
`;
