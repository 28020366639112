import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { fetchCourseTextbooks } from "data/slices/courses/courseTextbooks";
import WithAPILink from "components/ui/WithAPILink";
import { Textbook } from "./Textbook";
import { Spinner } from "components/ui/Spinner";
import { currentCourseState } from "utils/currentCourseState";

export function TextbooksCardBody({ currentSemester, currentCourse }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentCourse && currentSemester) {
      dispatch(fetchCourseTextbooks(currentCourse, currentSemester));
    }
  }, [dispatch, currentSemester, currentCourse]);

  const courseTextbooks = useSelector((state) => {
    return currentCourseState(currentCourse, state)?.courseTextbooks;
  });

  const isLawCourse = currentCourse.courseCareerCode === "LAW";
  const { loadState, books: { items: books = [], bookUnavailableError } = {} } =
    courseTextbooks || {};

  if (isLawCourse) {
    return (
      <WithAPILink urlID="UC_CX_LAW_BOOK" /> && (
        <div className="cc-widget-padding">
          Please visit the <WithAPILink urlID="UC_CX_LAW_BOOK" /> for textbook
          information of Law classes.
        </div>
      )
    );
  }

  if (loadState === "success") {
    if (books.length > 0) {
      return (
        <Textbooks data-testid="textbooks">
          {books.map((book, index) => (
            <Textbook book={book} key={index} />
          ))}
        </Textbooks>
      );
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: bookUnavailableError }}></div>
    );
  }

  return <Spinner />;
}

TextbooksCardBody.displayName = "TextbooksCardBody";
TextbooksCardBody.propTypes = {
  currentSemester: PropTypes.object,
  currentCourse: PropTypes.object,
};

const Textbooks = styled.div`
  display: flex;
  gap: var(--lg);
  flex-direction: column;
`;
