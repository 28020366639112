import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "functions/isEmpty";
import { Card, CardBody, CardTitle } from "components/ui/Card";
import { Spinner } from "components/ui/Spinner";
import { BulletList } from "components/ui/Lists";
import { ErrorBoundary } from "components/ui/ErrorBoundary";

import { useHistoricAcademicRoles } from "components/hooks/useAcademicRoles";
import { useFeatures } from "components/hooks/useFeatures";
import { useRoles } from "components/hooks/useRoles";
import { useSlice } from "components/hooks/useSlice";
import { useUser } from "components/hooks/useUser";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";

import { fetchAcademicRecords } from "data/slices/users/academicRecords";
import { fetchHasExamResults } from "data/slices/users/hasExamResults";

import { useReact } from "components/hooks/useReact";

export function AcademicRecordsCard() {
  return (
    <ErrorBoundary title="Academic Records">
      <AcademicRecordsCardUI />
    </ErrorBoundary>
  );
}

function AcademicRecordsCardUI() {
  dispatchUserSlice([fetchAcademicRecords, fetchHasExamResults]);

  const { student: isStudent, law: hasLawRole } = useRoles();
  const {
    transcriptRequestLinkCredSolutions: featureCredSolutions,
    enrollmentVerification: featureEnrollmentVerification,
  } = useFeatures();

  const {
    law: isHistorialLaw,
    ugrd: isHistoricalUgrd,
    grad: isHistoricalGrad,
    concurrent: isHistoricalConcurrent,
  } = useHistoricAcademicRoles();

  const {
    actingAsUid,
    delegateActingAsUid,
    advisorActingAsUid,
    isDirectlyAuthenticated,
    hasStudentHistory,
  } = useSlice("myStatus");

  const {
    loadState: recordsLoadState,
    officialTranscriptRequestData: {
      postParams: transcriptPostParams = {},
      postURL: transcriptPostURL = "",
    } = {},
  } = useUser((user) => user.academicRecords);

  const { loadState: resultsLoadState, hasExamResults } = useUser(
    (user) => user.hasExamResults
  );

  const loadState =
    recordsLoadState === "success" && resultsLoadState === "success"
      ? "success"
      : "pending";

  const requestTranscript = buildTranscriptRequest(
    transcriptPostURL,
    transcriptPostParams,
    featureCredSolutions
  );

  const requestLawTranscript = buildTranscriptRequest(
    transcriptPostURL,
    {
      // For law the fice code and dates need to be set.
      ...transcriptPostParams,
      fice: transcriptPostParams.lawFice,
      attfr: transcriptPostParams.lawAttfr,
      attto: transcriptPostParams.lawAttto,
    },
    featureCredSolutions
  );

  const transcriptError = isEmpty(transcriptPostURL);

  const isReactOnly = useReact();

  const showCard = (isStudent || hasStudentHistory) && !delegateActingAsUid;

  if (showCard) {
    return (
      <Card data-testid="academic-records-card">
        <CardTitle>
          <h2>Academic Records</h2>
        </CardTitle>
        <CardBody>
          {loadState === "pending" && <Spinner />}
          {loadState === "success" && (
            <>
              {transcriptError && (
                <>
                  There was an error retrieving electronic transcript access
                  data.
                </>
              )}

              <BulletList>
                {isHistorialLaw && (
                  <>
                    <li data-testid="law-transcript-electronic">
                      <a
                        onClick={requestLawTranscript}
                        disabled={delegateActingAsUid || transcriptError}
                        title="Transcript in electronic PDF format for Law students"
                      >
                        Transcript - Law (Electronic)
                      </a>
                      <div className="cc-list-links-sub-text">
                        Electronic PDF, $1.90 charge. Available for current
                        students and students who entered in Fall 1995 or later.
                      </div>
                    </li>

                    <li data-testid="law-transcript-printed">
                      <a
                        href="http://www.law.berkeley.edu/php-programs/registrar/forms/transcriptrequestform.php"
                        title="Transcript in printed format for Law students"
                      >
                        Transcript - Law (Printed)
                      </a>
                      <div className="cc-list-links-sub-text">
                        Printed for pick up or mailing at no charge.
                      </div>
                    </li>
                  </>
                )}

                {!hasLawRole && (isHistoricalUgrd || isHistoricalGrad) && (
                  <li data-testid="ugrd-grad-transcript">
                    <a
                      onClick={requestTranscript}
                      disabled={delegateActingAsUid || transcriptError}
                      title="Transcript for Undergraduate or Graduate Students"
                    >
                      Transcript - Undergraduate or Graduate Students
                    </a>
                  </li>
                )}

                {!hasLawRole && isHistoricalConcurrent && (
                  <li data-testid="ucbx-transcript">
                    <a
                      href="http://extension.berkeley.edu/static/studentservices/transcripts/#ordertranscripts"
                      title="Transcript for UCB Extension Concurrent Enrollment students"
                    >
                      Transcript - Concurrent Enrollment (UC Extension)
                    </a>
                  </li>
                )}

                {featureEnrollmentVerification &&
                  (isDirectlyAuthenticated ||
                    actingAsUid ||
                    advisorActingAsUid) && (
                    <li data-testid="enrollment-verification">
                      <Link
                        to={isReactOnly ? "enrollment_verification" : "/academics/enrollment_verification"}
                        title="Request Enrollment Verification"
                      >
                        Enrollment Verification
                      </Link>
                    </li>
                  )}

                {hasExamResults && (
                  <li data-testid="exam-results">
                    <Link
                      to={isReactOnly ? "exam_results" : "/academics/exam_results"}
                      title="Exam Results"
                    >
                      Exam Results
                    </Link>
                  </li>
                )}
              </BulletList>
            </>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
}
AcademicRecordsCard.displayName = "AcademicRecordsCard";

function buildTranscriptRequest(url, postParams, featureEnabled) {
  return function () {
    if (featureEnabled) {
      createWindowAndPost(url, postParams);
    } else {
      window.open(
        "http://registrar.berkeley.edu/academic-records/transcripts-diplomas",
        "_blank"
      );
    }
  };
}

// Constructs a post request to Credentials Solutions, as outlined by the Credentials Solutions documentation seen in
// https://confluence.ets.berkeley.edu/confluence/display/MYB/Academic+Records.
function createWindowAndPost(url, postParams) {
  const html = document.implementation.createDocument(null, "HTML");
  const head = document.createElement("HEAD");
  const body = document.createElement("BODY");
  const form = document.createElement("FORM");

  form.method = "post";
  form.action = url;
  form.setAttribute("name", "transcriptRequestForm");

  if (postParams) {
    Object.entries(postParams).forEach(([key, value]) => {
      const input = document.createElement("INPUT");
      input.type = "hidden";
      input.name = key.toUpperCase();
      input.value = value;
      form.appendChild(input);
    });
  }

  body.appendChild(form);
  html.documentElement.appendChild(head);
  html.documentElement.appendChild(body);

  const serializer = new XMLSerializer();
  const newWindow = window.open("");
  newWindow.document.write(serializer.serializeToString(html));
  newWindow.document.getElementsByName("transcriptRequestForm")[0].submit();
}
