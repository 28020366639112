import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledSubHeader = styled.span`
  font-size: 15px;
  font-weight: normal;
`;

export default function UnitsSubHeader({ units }) {
  if (!units || !units.totalUnits) return null;
  return (
    <StyledSubHeader>
      {` (Cumulative ${units.totalUnits.toFixed(1)})`}
    </StyledSubHeader>
  );
}

UnitsSubHeader.displayName = "UnitsSubHeader";
UnitsSubHeader.propTypes = { units: PropTypes.object };
