import React from "react";
import PropTypes from "prop-types";

import searchIcon from "icons/search.svg";
import styled from "styled-components";

const BillingItemSearch = ({ search, setSearch }) => (
  <BillingItemSearchContainer>
    <label>Search</label>
    <input
      type="search"
      value={search}
      onChange={(e) => setSearch(e.currentTarget.value)}
    />
  </BillingItemSearchContainer>
);
BillingItemSearch.displayName = "BillingItemSearch";
BillingItemSearch.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
};

export default BillingItemSearch;
export const BillingItemSearchContainer = styled.div`
  width: 100%;

  label {
    font-size: 10px;
    text-transform: uppercase;
    color: var(--black);
  }

  input {
    border: 1px solid #cbcbcb;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: center;

    font-size: $base-font-size;
    padding-left: 25px;
    padding-right: 10px;
    height: 30px;
    line-height: 14px;
    width: 100%;
  }

  @media only screen and (min-width: 576px) {
    width: 130px;
    margin: 0px;
    label {
      display: none;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 1180px) {
    width: 100%;
    margin: 0px;
    label {
      display: none;
    }
  }
`;
