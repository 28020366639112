import React from "react";
import PropTypes from "prop-types";
import DisclosureChevron from "react/components/DisclosureChevron";
import ButtonAsLink from "components/ui/ButtonAsLink";
import { FormatCurrency } from "components/ui/FormatCurrency";
import { CategoryItemRow } from "./CategoryItemRow";

export function GroupItem({ item, isExpanded, onClick }) {
  const { title, total, amounts = [], subItems = [] } = item;

  return (
    <tbody className="hoverFocus">
      <>
        <tr>
          <td>{title}</td>

          {amounts.map((amount, index) => (
            <td key={index} className="amount">
              {!isExpanded && <FormatCurrency value={amount} />}
            </td>
          ))}

          <td className="total">
            {!isExpanded && <FormatCurrency value={total} />}
          </td>

          <td className="controls">
            <ButtonAsLink onClick={onClick}>
              <DisclosureChevron expanded={isExpanded} />
            </ButtonAsLink>
          </td>
        </tr>

        {isExpanded &&
          subItems.map((categoryItem, index) => (
            <CategoryItemRow subItem={categoryItem} key={index} />
          ))}
      </>
    </tbody>
  );
}

GroupItem.displayName = "GroupItem";
GroupItem.propTypes = {
  isExpanded: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amounts: PropTypes.array,
    subItems: PropTypes.array,
    totals: PropTypes.array,
  }),
  onClick: PropTypes.func,
};
