import React from "react";
import PropTypes from "prop-types";

import { NoDataPlaceholder } from "./StudentSuccessModule";

export default function GpaTemplate({ lookupKey, units }) {
  if (!units || !units[lookupKey]) {
    return <NoDataPlaceholder />;
  }

  return <span>{units[lookupKey].toFixed(1)}</span>;
}

GpaTemplate.displayName = "GpaTemplate";
GpaTemplate.propTypes = {
  units: PropTypes.object,
  lookupKey: PropTypes.string,
};
