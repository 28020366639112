import React from "react";
import { react2angular } from "react2angular";
import { ReduxProvider } from "components/data/ReduxProvider";
import { PNPCalculatorCard } from "components/cards/PNPCalculatorCard";

function NgPNPCalculatorCard() {
  return (
    <ReduxProvider>
      <PNPCalculatorCard />
    </ReduxProvider>
  );
}

angular
  .module("calcentral.react")
  .component("pnpCalculatorCard", react2angular(NgPNPCalculatorCard));
