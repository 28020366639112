import React from "react";
import PropTypes from "prop-types";

import formatCurrency from "functions/formatCurrency";
import formatDate from "functions/formatDate";

import styled from "styled-components";
import { parseISO } from "date-fns";

const ItemPayment = ({ payment }) => {
  return (
    <ItemPaymentStyles>
      <li className="ItemPayment">
        <div className="flex">
          <div className="ItemPayment__description">
            <div>{payment.description}</div>
            <div className="ItemPayment__paid-on">
              Paid on {formatDate(parseISO(payment.effective_date))}
            </div>
          </div>
          <div className="ItemPayment__amount">
            {formatCurrency(Math.abs(payment.amount_paid))}
          </div>
        </div>
      </li>
    </ItemPaymentStyles>
  );
};
ItemPayment.displayName = "ItemPayment";
ItemPayment.propTypes = {
  payment: PropTypes.object,
};

export default ItemPayment;
export const ItemPaymentStyles = styled.div`
  .ItemPayment .flex {
    display: flex;
    justify-content: space-between;
    margin-right: 300px;
  }

  .ItemPayment {
    padding-left: 0;
  }

  .ItemPayment + .ItemPayment {
    margin-top: 10px;
  }

  .ItemPayment__paid-on {
    color: var(--dove-gray);
  }

  .ItemPayment__amount {
    padding-right: 12px;
  }
`;
