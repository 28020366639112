import React, { useState } from "react";
import PropTypes from "prop-types";
import { Disclosure, DisclosurePanel } from "@reach/disclosure";
import { Task } from "./Task";
import { taskShape } from "components/propTypes/taskShape";
import { DoubleChevronDisclosureButton } from "components/ui/Disclosure";
import { VisuallyHidden } from "components/ui/VisuallyHidden";
import { IncompleteDueSoonBadges } from "./IncompleteDueSoonBadges";
import {
  BeingProcessedCount,
  TasksWrapper,
  CategoryHeader,
} from "./CategoryUI";

export function Category({ title, subtitle, tasks = [], testid }) {
  const [open, setOpen] = useState(false);
  const itemsIncomplete = tasks.filter((item) => item.isIncomplete);
  const itemsBeingProcessed = tasks.filter((item) => item.isBeingProcessed);
  const incompleteCount = itemsIncomplete.length;
  const inProcessCount = itemsBeingProcessed.length;

  if (tasks.length) {
    return (
      <Disclosure
        data-testid={testid}
        open={open}
        onChange={() => setOpen(!open)}
      >
        <CategoryHeader open={open}>
          <div>
            <h3>
              {title}{" "}
              {subtitle && <span className="aidYearLabel">{subtitle}</span>}
            </h3>
            <div>
              <IncompleteDueSoonBadges tasks={tasks} />
              {inProcessCount > 0 && (
                <div>{inProcessCount} being processed</div>
              )}
            </div>
          </div>

          <DoubleChevronDisclosureButton open={open}>
            <VisuallyHidden>{open ? "Hide" : "View"}</VisuallyHidden>
          </DoubleChevronDisclosureButton>
        </CategoryHeader>

        <DisclosurePanel>
          {incompleteCount > 0 && (
            <TasksWrapper>
              {itemsIncomplete.map((task, index) => (
                <Task key={index} task={task} />
              ))}
            </TasksWrapper>
          )}
          {inProcessCount > 0 && (
            <>
              <BeingProcessedCount count={inProcessCount} />
              <TasksWrapper>
                {itemsBeingProcessed.map((task, index) => (
                  <Task key={index} task={task} index={index} />
                ))}
              </TasksWrapper>
            </>
          )}
        </DisclosurePanel>
      </Disclosure>
    );
  }

  return null;
}

Category.displayName = "Category";
Category.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  tasks: PropTypes.arrayOf(taskShape),
  testid: PropTypes.string,
  indices: PropTypes.arrayOf(PropTypes.number),
};
