import styled from "styled-components";
import calendarIcon from "icons/calendar.svg";
import pinIcon from "icons/pin.svg";
import tagIcon from "icons/tag.svg";
import clockIcon from "icons/clock.svg";

const IconHeaderCell = styled.th`
  color: var(--dove-gray);
  font-weight: normal;
  white-space: nowrap;
  vertical-align: top;
  line-height: 20px;

  // Icon specific
  background-repeat: no-repeat;
  background-position-y: 5px;
  padding-left: 15px;
  padding-right: 15px;
`;
IconHeaderCell.displayName = "IconHeaderCell";

export const InstructionModeHeaderCell = styled(IconHeaderCell)`
  background-image: url("${pinIcon}");
`;
InstructionModeHeaderCell.displayName = "InstructionModeHeaderCell";

export const ScheduleHeaderCell = styled(IconHeaderCell)`
  background-image: url("${calendarIcon}");
`;
ScheduleHeaderCell.displayName = "ScheduleHeaderCell";

export const MeetingDetailsHeaderCell = styled(IconHeaderCell)`
  background-image: url("${tagIcon}");
`;
MeetingDetailsHeaderCell.displayName = "MeetingDetailsHeaderCell";

export const IndividualSessionsHeaderCell = styled(IconHeaderCell)`
  background-image: url("${clockIcon}");
`;
IndividualSessionsHeaderCell.displayName = "IndividualSessionsHeaderCell";
