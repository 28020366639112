import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import iconCal from "assets/images/icon_cal_256x256.svg";
import amazonIcon from "assets/images/icon_amazon_256x256.svg";
import cheggIcon from "assets/images/icon_chegg_256x256.svg";
import googlebookIcon from "assets/images/icon_googlebook_32x32.png";
import oskicatIcon from "assets/images/icon_oskicat_16x16.png";

import { UnstyledList } from "components/ui/Lists";
import { MediumSpacer } from "components/ui/VerticalSpacers";

export const Textbook = ({ book }) => {
  const { image, title } = book;
  const headers = ["Author", "ISBN", "Edition", "Publisher"];

  const services = [
    {
      testid: "cal-student-bookstore",
      icon: iconCal,
      description: "Purchase",
      url: book.calStudentStoreLink,
    },
    {
      testid: "amazon",
      icon: amazonIcon,
      description: "Purchase",
      url: book.amazonLink,
    },
    {
      testid: "chegg",
      icon: cheggIcon,
      description: "Rent",
      url: book.cheggLink,
    },
    {
      testid: "google-books",
      icon: googlebookIcon,
      description: "Purchase",
      url: book.googlebookLink,
    },
    {
      testid: "oskicat",
      icon: oskicatIcon,
      description: "Borrow",
      url: book.oskicatLink,
    },
  ].filter((s) => s.url !== null);

  return (
    <TextbookStyles data-testid="textbook">
      <div>
        {image ? (
          <img src={image} alt={`Textbook cover for ${title}`} width="128" />
        ) : (
          <NoBookImage data-testid="no-image">Image not available</NoBookImage>
        )}
      </div>
      <div>
        <h4 data-testid="title">{title}</h4>
        <BookPropertiesStyles data-testid="properties">
          {headers.map((header) => {
            const key = header.toLowerCase();

            if (book[key]) {
              return (
                <React.Fragment key={key}>
                  <dt data-testid={key}>{header}</dt>
                  <dd data-testid={key}>{book[key]}</dd>
                </React.Fragment>
              );
            }

            return null;
          })}
        </BookPropertiesStyles>

        <ServicesStyles data-testid="services">
          {services.map(({ testid, icon, url, description }) => (
            <li key={icon} data-testid={testid}>
              <img src={icon} width="16" height="16" />{" "}
              <a href={url} target="_blank" rel="noreferrer">{description}</a>
            </li>
          ))}
        </ServicesStyles>
        <MediumSpacer />
      </div>
    </TextbookStyles>
  );
};

Textbook.displayName = "Textbook";
Textbook.propTypes = {
  book: PropTypes.object,
};

const TextbookStyles = styled.div`
  display: flex;
  gap: var(--md);

  h4 {
    font-size: 14px;
    line-height: 15px;
  }

  img {
    max-width: 100%;
    border: 1px solid #eee;
  }
`;

const BookPropertiesStyles = styled.dl`
  font-size: 11px;
  line-height: 15px;

  dt {
    display: inline;
    font-weight: bold;
    &::after {
      content: ": ";
    }
  }

  dd {
    display: inline;
    margin: 0;

    &::after {
      content: "\\a";
      white-space: pre;
    }
  }
`;

const ServicesStyles = styled(UnstyledList)`
  display: flex;
  row-gap: var(--sm);
  flex-wrap: wrap;

  font-size: 11px;
  line-height: 15px;
  font-weight: bold;

  li {
    min-width: 50%;
  }

  img {
    vertical-align: text-top;
  }
`;

const NoBookImage = styled.div`
  background: var(--gallery);
  max-width: 130px;
  height: 100%;

  font-size: 15px;
  text-align: center;
  padding-top: var(--lg);
`;
