import React, { useState } from "react";
import PropTypes from "prop-types";
import { parseISO } from "date-fns";

import ItemAdjustment, { GenericAdjustment } from "./ItemAdjustment";
import formatCurrency from "functions/formatCurrency";
import timelineCenterBlue from "icons/timeline-center-blue.svg";
import styled from "styled-components";

const TransactionHistory = ({
  item: { adjustments, amount: itemAmount, type: itemType },
}) => {
  const [showAll, setShowAll] = useState(false);

  const first = adjustments[0];
  const original = adjustments[adjustments.length - 1];
  const changes = [...adjustments.slice(0, -1)];
  const firstFour = [...changes.slice(0, 4)];
  const nextFour = [...changes.slice(4, 8)];
  const tooMany = changes.length > 8;

  return (
    <StyledTransactionHistory>
      <h3>Transaction Amount History</h3>

      {adjustments.length > 1 ? (
        <div className="ItemAdjustments">
          <GenericAdjustment
            className="ItemAdjustment--first"
            date={parseISO(first.posted)}
            description={`Current Amount: ${formatCurrency(
              Math.abs(itemAmount)
            )}`}
          />

          {firstFour.map((adjustment, index) => (
            <ItemAdjustment
              key={index}
              adjustment={adjustment}
              itemType={itemType}
            />
          ))}

          {nextFour.length > 0 && (
            <ShowMoreItem
              className="ItemAdjustment ItemAdjustment--show-more"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "Show Less" : "Show More"}
            </ShowMoreItem>
          )}

          {showAll &&
            nextFour.map((adjustment, index) => (
              <ItemAdjustment
                key={index}
                adjustment={adjustment}
                itemType={itemType}
              />
            ))}
          {showAll && tooMany && (
            <li className="ItemAdjustment ItemAdjustment--too-many">
              Too many earlier changes to show
            </li>
          )}

          <GenericAdjustment
            className="ItemAdjustment--last"
            date={parseISO(original.posted)}
            description={`Original Amount: ${formatCurrency(
              Math.abs(original.amount)
            )}`}
          />
        </div>
      ) : (
        <span className="NoChanges">No changes to show</span>
      )}
    </StyledTransactionHistory>
  );
};
TransactionHistory.displayName = "TransactionHistory";
TransactionHistory.propTypes = {
  item: PropTypes.object,
};

export default TransactionHistory;
export const StyledTransactionHistory = styled.div`
  padding: 10px;

  h3 {
    font-size: var(--base-font-size);
    margin-top: 0;
  }

  .ItemDetails .TransactionHistory {
    grid-column: 1/1;
  }

  @media only screen and (min-width: 576px) {
    .ItemDetails .TransactionHistory {
      grid-column: 1/3;
    }
  }

  .ItemAdjustments {
    margin-left: 5px;
  }

  .NoChanges {
    color: var(--dove-gray);
  }
`;

export const ShowMoreItem = styled.li`
  display: flex;
  background-image: url(${timelineCenterBlue});
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 15px;
  margin-left: -4px;
  line-height: 23px;
  color: #2683bc;
`;
