import React from "react";

import { CCTable } from "components/ui/Table";
import { LargeSpacer } from "components/ui/VerticalSpacers";

import TermRow from "./TermRow";
import { NoDataPlaceholder } from "./StudentSuccessModule";

import { useAdvisingStudent } from "components/hooks/useAdvisingStudent";

export default function TermGpaTable() {
  const { termGPAWithZero } = useAdvisingStudent(
    (student) => student.advisingStudentSuccess
  );

  const sortedTermGPA = termGPAWithZero.concat().sort(sortByKey("termId"));

  if (sortedTermGPA.length) {
    return (
      <>
        <LargeSpacer />
        <CCTable>
          <thead>
            <tr>
              <th width="84%">Term</th>
              <th width="84%" style={{ textAlign: "right" }}>
                GPA
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedTermGPA.map((term, index) => {
              if (!term) return null;
              const formattedGPA = (Boolean(term.termGPA) &&
                term.termGPA.toFixed(3)) || <NoDataPlaceholder />;
              return (
                <TermRow
                  key={index}
                  index={index}
                  term={term}
                  formattedGpa={formattedGPA}
                />
              );
            })}
          </tbody>
        </CCTable>
        <LargeSpacer />
      </>
    );
  }

  return null;
}

TermGpaTable.displayName = "TermGpaTable";

const sortByKey = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};
