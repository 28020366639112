import React from "react";
import styled from "styled-components";

const DownloadButton = () => {
  const onClick = () => {
    document.location.pathname = "/api/my/finances/billing_items.csv";
  };

  return <DownloadStyles onClick={() => onClick()}>Download</DownloadStyles>;
};
DownloadButton.displayName = "DownloadButton";
export default DownloadButton;

export const DownloadStyles = styled.div`
   {
    color: var(--dove-gray);
    background: var(--wild-sand);
    border: 1px solid var(--silver);
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: bold;
  }
`;
