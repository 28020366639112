import React from "react";
import { useSelector } from "react-redux";
import { Card, CardTitle, CardBody } from "components/ui/Card";
import { dispatchUserSlice } from "components/hooks/dispatchUserSlice";
import { fetchPnpCalculatorValues } from "data/slices/users/pnpCalculatorValues";
import { fetchPnpRatioMessage } from "data/slices/users/pnpRatioMessage";
import { useUser } from "components/hooks/useUser";
import { Spinner } from "components/ui/Spinner";
import { ErrorBoundary } from "components/ui/ErrorBoundary";
import { PercentageEstimator } from "./PNPCalculatorCard/PercentageEstimator";

const title = "1/3 Passed Grade Calculator";

export function PNPCalculatorCard() {
  const showPNPCard = useSelector((state) => state.showPNPCard);

  if (showPNPCard) {
    return (
      <ErrorBoundary title={title}>
        <PNPCalculatorCardUI />
      </ErrorBoundary>
    );
  }

  return null;
}

export function PNPCalculatorCardUI() {
  dispatchUserSlice(fetchPnpCalculatorValues);
  dispatchUserSlice(fetchPnpRatioMessage);

  const { loadState: calculatorLoadState } = useUser(
    (slice) => slice.pnpCalculatorValues
  );

  const { loadState: messageLoadState } = useUser(
    (slice) => slice.pnpRatioMessage
  );

  const isReady =
    calculatorLoadState === "success" && messageLoadState === "success";

  return (
    <Card>
      <CardTitle>
        <h2>{title}</h2>
      </CardTitle>
      <CardBody>{isReady ? <PercentageEstimator /> : <Spinner />}</CardBody>
    </Card>
  );
}
