import React, { Fragment } from "react";
import PropTypes from "prop-types";

import formatDate from "functions/formatDate";
import DisclosureChevron from "components/../react/components/DisclosureChevron";
import styled from "styled-components";

import ItemDetails from "./ItemDetails";
import ChargeAmountDue from "./ChargeAmountDue";
import ChargeStatus from "./ChargeStatus";
import ItemAmount from "./ItemAmount";
import ItemUpdated from "./ItemUpdated";

const propTypes = {
  item: PropTypes.object,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  tab: PropTypes.string,
};

const DesktopView = ({ item, expanded, onExpand }) => {
  return (
    <ChargeItemStyles
      className={`${expanded ? "BillingItem--expanded" : ""}`}
      onClick={() => onExpand()}
    >
      <ChargeItemGrid
        className={`BillingItem BillingItem--charge BillingItem--desktop ${
          expanded ? "BillingItem--expanded" : ""
        }`}
        onClick={() => onExpand()}
      >
        <div className="TableColumn__posted">{formatDate(item.postedOn)}</div>
        <div className="TableColumn__description-amount">
          <div className="TableColumn__description">
            <div className="BillingItem__description">{item.description}</div>
            <div className="BillingItem__type">{item.type}</div>
          </div>
          <div className="TableColumn__amount">
            <ItemAmount amount={item.amount} />
            <ItemUpdated item={item} />
          </div>
        </div>
        <div className="TableColumn__status">
          <ChargeStatus item={item} icon={true} />
        </div>
        <div className="TableColumn__due ChargeItem__due">
          <ChargeAmountDue item={item} icon={false} />
        </div>
        <div className="TableColumn__chevron">
          <DisclosureChevron expanded={expanded} />
        </div>
      </ChargeItemGrid>
      {expanded && <ItemDetails item={item} />}
    </ChargeItemStyles>
  );
};
DesktopView.displayName = "DesktopView";
DesktopView.propTypes = propTypes;

const ChargeItem = (props) => {
  return (
    <Fragment>
      <DesktopView {...props} />
    </Fragment>
  );
};
ChargeItem.displayName = "ChargeItem";
ChargeItem.propTypes = propTypes;

export default ChargeItem;
export const ChargeItemGrid = styled.div`
  display: grid;
  grid-template-columns: 82px 3fr 1fr 120px 10px;
  column-gap: 20px;
  padding: 10px;

  .TableColumn__description-amount {
    display: flex;
    justify-content: space-between;

    .TableColumn__description {
      margin-right: 20px;
    }
  }

  .TableColumn__due {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.ChargeItem__due {
      flex-direction: column;
      align-items: flex-end;
      justify-content: start;
      color: var(--tundora);
      padding-right: 12px;
    }
  }

  .TableColumn__chevron {
    width: 10px;
    display: grid;
    place-items: center center;
  }

  .TableColumn__amount {
    text-align: right;
  }
`;
export const ChargeItemStyles = styled.div`
  &.BillingItem--expanded,
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }
`;
