import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { ModernBlueButton } from "components/ui/Buttons";
import { useReact } from "components/hooks/useReact";
import { ButtonAsLink } from "components/ui/ButtonAsLink";
import { RosterCardContext } from "./RosterCardContext";

export function PrintExport() {
  return (
    <div
      style={{
        display: `flex`,
        alignItems: `center`,
        gap: `var(--lg)`,
      }}
    >
      <ExportButton />{" "}
      <ModernBlueButton onClick={() => window.print()}>
        <i className="fa fa-print" /> Print
      </ModernBlueButton>
    </div>
  );
}

PrintExport.propTypes = {
  courseId: PropTypes.string,
  currentSectionCCN: PropTypes.string,
  enrollmentFilter: PropTypes.string,
};

function ExportButton() {
  const isReact = useReact();
  const { classId } = useParams();
  const { currentSectionCCN, enrollmentFilter } = useContext(RosterCardContext);

  const downloadURL = `/api/academics/rosters/campus/csv/${classId}.csv?section_id=${currentSectionCCN}&enroll_option=${enrollmentFilter}`;

  if (isReact) {
    return (
      <a href={downloadURL}>
        <i className="fa fa-download" /> Export
      </a>
    );
  }

  return (
    <ButtonAsLink onClick={() => (window.location.href = downloadURL)}>
      <i className="fa fa-download" /> Export
    </ButtonAsLink>
  );
}
