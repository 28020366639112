import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormatCurrency } from "components/ui/FormatCurrency";

export const TypeSummary = ({ amounts, categoryTitle, headers, total }) => {
  const titleStyles = {
    textTransform: "uppercase",
    textAlign: "right",
    color: "var(--dove-gray)",
  };
  const columnCount = headers.length;

  if (total) {
    return (
      <TypeSummaryContainer className="summary">
        <tr className="titleRow">
          <td colSpan={columnCount + 1} style={titleStyles}>
            {categoryTitle + " Total"}
          </td>
          <td className="controls">{''}</td>
        </tr>

        <tr>
          <td>{''}</td>
          {amounts && amounts.map((amount, index) => (
            <td key={index} className="total">
              <FormatCurrency value={amount} />
            </td>
          ))}
          <td className="total">{total && <FormatCurrency value={total} />}</td>
          <td className="controls">{''}</td>
        </tr>
      </TypeSummaryContainer>
    );
  } else {
    return null;
  }
};
TypeSummary.displayName = "TypeSummary";
TypeSummary.propTypes = {
  amounts: PropTypes.array,
  categoryTitle: PropTypes.string,
  headers: PropTypes.array,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const TypeSummaryContainer = styled.tbody`
  tr {
    td.total {
      text-align: right;
    }
  }
`;

export const GrandTotal = ({ amounts = [], headers = [], total }) => {
  return (
    <GrandTotalContainer>
      <tr className="headerRow">
        <td className="title">Grand Total</td>
        {headers && headers.map((header, index) => (
          <td key={index}>{header}</td>
        ))}
      </tr>
      <tr className="amountsRow">
        <td>{''}</td>
        {amounts && amounts.map((amount, index) => (
          <td key={index} className="total">
            <FormatCurrency value={amount} />
          </td>
        ))}
        {total && (
          <td><FormatCurrency value={total} /></td>
        )}
      </tr>
    </GrandTotalContainer>
  );
};

GrandTotal.displayName = "GrandTotal";
GrandTotal.propTypes = {
  amounts: PropTypes.array,
  headers: PropTypes.array,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const GrandTotalContainer = styled.tbody`
  tr.headerRow {
    td.title {
      text-align: left;
    }
    td {
      border-bottom: 1px solid var(--silver);
      color: var(--dove-gray);
      font-weight: bold;
      padding-top: var(--lg);
      text-align: right;
    }
  }
  tr.amountsRow {
    td {
      font-weight: bold;
      text-align: right;
    }
  }
`;
